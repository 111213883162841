import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Stack, SxProps } from "@mui/material";
import { RuFlag } from "../../images/RuFlag";
import { LanguageType } from "../../graphql/generated";
import { getSegmentUrl } from "../../utils";

interface LanguagePickerProps {
  sx?: SxProps;
  width?: string;
  maxWidth?: string;
  options?: LanguageType[];
  name: string;
}

export const LanguagePicker = ({
  sx,
  width = "130px",
  maxWidth = "130px",
  options,
  name,
}: LanguagePickerProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl sx={{ width, maxWidth, ...sx }}>
            <InputLabel id="lang-select-label">Project language</InputLabel>
            <Select
              labelId="lang-select-label"
              onChange={onChange}
              value={value}
              label="Project language">
              {options?.map((option) => {
                return (
                  <MenuItem value={option.value} key={option.value}>
                    <Stack direction={"row"} gap={"15px"}>
                      {option.value !== "ru" ? (
                        <img
                          width={"30"}
                          src={`https://flagcdn.com/h40/${getSegmentUrl(
                            option.value
                          ).toLowerCase()}.webp`}
                          alt={"i"}
                        />
                      ) : (
                        <RuFlag />
                      )}
                      {option.value.toUpperCase()}
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};
