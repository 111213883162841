import * as yup from "yup";

export const hasStartedAtFieldTaskSchemaCreate = yup.object().shape({
  name: yup.string().required(),
  startedAt: yup.date().required(),
  duration: yup
    .number()
    .typeError("Duration must be a number")
    .required()
    .positive()
    .required(),
  developerId: yup
    .number()
    .typeError("Select Developer is a required field")
    .required(),
});

export const hasNotStartedAtFieldTaskSchemaCreate = yup.object().shape({
  name: yup.string().required(),
  duration: yup
    .number()
    .typeError("Duration must be a number")
    .required()
    .positive()
    .required(),
  developerId: yup
    .number()
    .typeError("Select Developer is a required field")
    .required(),
});
