import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ErrorImage from "images/404-img.jpeg";
import { useNavigate } from "react-router-dom";
import { styles } from "screens/404Screen/styles";
import Layout from "components/Layout/Layout";

const Screen404: React.FC = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const goHome = () => {
    navigate("/");
  };

  const Wrapper = token ? Layout : React.Fragment;

  return (
    <Wrapper>
      <Container sx={styles.container} maxWidth="xl">
        <Box>
          <Typography align="left" sx={styles.title} variant="h1">
            404
          </Typography>
          <Typography sx={styles.subtitle} variant="h6">
            The page you’re looking for doesn’t exist.
          </Typography>
          <Button
            color="success"
            size="large"
            variant="contained"
            onClick={goHome}>
            Back Home
          </Button>
        </Box>
        <Box>
          <img src={ErrorImage} alt="" width={700} height={500} />
        </Box>
      </Container>
    </Wrapper>
  );
};
export default Screen404;
