export const styles = {
  container: {
    width: "43vw",
    padding: "8px",
    position: "relative",
  },
  title: {
    marginBottom: "24px",
  },
  inputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    margin: "8px 0px 8px 0px",
  },
  button: {
    width: "100%",
  },
  linksContainer: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
  },

  editorWrapper: (error?: string) => {
    return {
      height: "170px",
      padding: error ? "2px" : "",
      border: error ? "1px solid #f46e6f" : "",
      borderRadius: "5px",
      marginBottom: "150px",
    };
  },
};
