import Box from "@mui/material/Box";

export const RuFlag = () => {
  return (
    <Box
      sx={{
        width: "30px",
        height: "23px !important",
        background: "#adadad",
      }}
    />
  );
};
