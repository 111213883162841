export const styles = {
  container: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    minHeight: "100vh",
  },
  title: {
    color: "#81d696",
    fontWeight: "bold",
  },
  subtitle: {
    color: "#102415",
    margin: "16px 0px",
  },
  button: {
    backgroundColor: "#265431",
  },
};
