export const getColorStatus = (status?: string) => {
  switch (status) {
    case "ACTIVE":
      return "#63b244";
    case "INACTIVE":
      return "#c5ab10";
    case "ARCHIVED":
      return "#c5ab10";
    case "BANNED":
      return "#b60000";
    default:
      return "inherit";
  }
};
