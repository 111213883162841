import React, { useState } from "react";
import UsersTable from "components/UsersTable";
import Layout from "components/Layout/Layout";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import AddIcon from "@mui/icons-material/Add";
import { useGetUsersQuery, UserEntity } from "graphql/generated";
import Button from "@mui/material/Button";
import { styles } from "screens/UsersScreen/styles";
import { useModalContext } from "context/ModalContext";
import { limitItemsCount, TYPE_MODAL } from "constants/index";

const UsersScreen = () => {
  const [pageNumberState, setPageNumberState] = useState<number>(0);

  const { data } = useGetUsersQuery({
    variables: {
      input: {
        offset: pageNumberState * limitItemsCount,
        limit: limitItemsCount,
      },
    },
  });

  const rows: Array<Partial<UserEntity>> = data?.getUsers.users || [];
  const count = data?.getUsers.count || 0;

  const { handleChangeModalParameters } = useModalContext();

  const handleCloseModalCreate = () => {
    handleChangeModalParameters({
      isOpen: false,
      type: TYPE_MODAL.CREATE_USER,
    });
  };

  const handleOpenModalCreate = () => {
    handleChangeModalParameters({
      isOpen: true,
      type: TYPE_MODAL.CREATE_USER,
      cancelAction: handleCloseModalCreate,
      params: {
        pageNumber: pageNumberState,
      },
    });
  };

  return (
    <Layout namePage="Пользователи">
      <Box sx={styles.container}>
        <Button
          variant="contained"
          onClick={handleOpenModalCreate}
          sx={styles.buttonCreate}>
          <Icon sx={styles.buttonIcon}>
            <AddIcon />
          </Icon>
          New User
        </Button>
        <UsersTable
          count={count}
          rows={rows}
          page={pageNumberState}
          setPageNumberState={setPageNumberState}
        />
      </Box>
    </Layout>
  );
};
export default UsersScreen;
