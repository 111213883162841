import { setHours, setMinutes, setSeconds } from "date-fns";
import { StoryEntity } from "graphql/generated";

interface StoryWithAlias extends StoryEntity {
  startedAtStory: StoryEntity["startedAt"];
}
export const getDefaultStoryStartDate = (): Date => {
  let date = new Date();
  date = setHours(date, 8);
  date = setMinutes(new Date(date), 0);
  date = setSeconds(new Date(date), 0);

  return date;
};

export const getStoryStartedDateValue = (story: StoryWithAlias) => {
  const constructorValue =
    typeof story.startedAtStory === "string"
      ? Number.parseInt(story.startedAtStory)
      : getDefaultStoryStartDate().getTime();

  return new Date(constructorValue);
};
