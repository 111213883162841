import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AppBar, Drawer, DrawerHeader } from "./styles";
import { projectName, ROUTES } from "constants/index";
import { useNavigate } from "react-router-dom";
import { useGetLanguagesQuery, useGetUserQuery } from "graphql/generated";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDrawerContext } from "context/DrawerContext";
import { CustomLink } from "components";

interface LayoutProps {
  children?: React.ReactNode;
  namePage?: string;
}

export default function Layout({
  children,
  namePage = "Проекты",
}: LayoutProps) {
  const { open, setOpen } = useDrawerContext();

  const { data } = useGetUserQuery();
  useGetLanguagesQuery();
  const navigate = useNavigate();

  const accessRoleRoutes = () => {
    return data?.getUser?.role === "ADMIN" ? ROUTES : [ROUTES[0]];
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem("open", "1");
    setTimeout(() => {
      gantt.render();
    }, 500);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("open", "");
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate(0);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center" flex={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h1" noWrap>
              {namePage}
            </Typography>
          </Box>
          <Typography mr={3}>{data?.getUser?.name}</Typography>
          <IconButton color="inherit" onClick={logout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box mr="40px">
            <Typography variant="h1">{projectName}</Typography>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {accessRoleRoutes().map((route, index) => {
            return (
              <CustomLink
                to={route.path}
                key={index}
                open={open}
                routeName={route.name}
              />
            );
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
