export const getInitials = (name = "") => {
  const chunks = name.split(" ");
  let initials = chunks[0]?.[0] || "A";

  if (chunks.length > 1) {
    initials = `${chunks[0][0]}${chunks[1][0]}`;
  }

  return initials.toUpperCase();
};
