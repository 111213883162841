import { useGetUserQuery } from "../graphql/generated";

export const useUserAccess = () => {
  const { data } = useGetUserQuery();
  const role = data?.getUser?.role;

  const isAdmin = role === "ADMIN";
  const isManager = role === "MANAGER";
  const isEstimator = role === "ESTIMATOR";

  return { isAdmin, isManager, isEstimator };
};
