import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { settingsTabSchema } from "validations/SettingsTabValidations/settingsTabSchema";
import TextField from "@mui/material/TextField";
import { styles } from "screens/ProjectDetailsScreen/SettingsTab/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { EditorController } from "components";
import Container from "@mui/material/Container";
import {
  useChangeProjectLanguageMutation,
  useEditProjectMutation,
  useGetLanguagesQuery,
  useGetUsersQuery,
  UserRoleEnum,
} from "graphql/generated";
import {
  FormSettingsData,
  SettinsTabProps,
} from "screens/ProjectDetailsScreen/types";
import { LanguagePicker } from "../../../components/LanguagePicker/LanguagePicker";
import Stack from "@mui/material/Stack";
import { AutocompleteRHF, SelectRHF } from "../../../components/UI";
import { useUserAccess } from "../../../hooks";
import {
  getFilterRoleData,
  getFormatDataForSelectAndAutocomplete,
} from "../../../utils";
import { PROJECT_STATUS } from "../../../constants";

const SettingsTab: React.FC<SettinsTabProps> = ({
  project,
  setIsChart,
  isChart,
}) => {
  const { name, recommendations, comments, aboutUs, id, status } = project;

  const [editProjectMutation] = useEditProjectMutation();
  const { data } = useGetLanguagesQuery();
  const { isAdmin, isManager, isEstimator } = useUserAccess();
  const [changeLang] = useChangeProjectLanguageMutation();

  const { data: usersData } = useGetUsersQuery({
    variables: {
      input: {
        offset: 0,
        limit: Infinity,
      },
    },
    skip: isEstimator,
  });

  const estimatorsData = getFilterRoleData(
    usersData?.getUsers.users,
    UserRoleEnum.Estimator
  );

  const managerData = getFilterRoleData(
    usersData?.getUsers.users,
    UserRoleEnum.Manager
  );

  const users = getFormatDataForSelectAndAutocomplete(managerData);
  const estimators = getFormatDataForSelectAndAutocomplete(estimatorsData);

  const INIT = {
    recommendations: recommendations || "<p></p>",
    comments: comments || "<p></p>",
    aboutUs: aboutUs || "<p></p>",
    ...(!isEstimator && {
      name,
      status,
      isWithChart: isChart,
      projectEstimators: getFormatDataForSelectAndAutocomplete(
        project.projectEstimators
      ),
      ...(isAdmin && { userId: String(project.user.id) }),
    }),
  };

  const methods = useForm<FormSettingsData>({
    mode: "onBlur",
    resolver: yupResolver(
      settingsTabSchema({ isAdmin, isManager, isEstimator })
    ),
    defaultValues: INIT,
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = methods;

  const langMethods = useForm({
    defaultValues: {
      language: project.language,
    },
  });

  const { watch: languageWatch } = langMethods;
  const lp = languageWatch("language");

  useEffect(() => {
    if (lp === project.language) return;
    changeLang({
      variables: {
        changeProjectLanguageId: Number(project.id),
        language: lp,
      },
    });
  }, [lp]);

  const onSubmit = async (data: FormSettingsData) => {
    const projectEstimators = data.projectEstimators?.map((est) =>
      Number(est.id)
    );

    await editProjectMutation({
      variables: {
        editProjectId: Number(id),
        input: {
          name: data.name,
          status: data.status,
          recommendations: data.recommendations,
          isWithChart: data.isWithChart,
          aboutUs: data.aboutUs,
          comments: data.comments,
          ...(data.userId && { userId: Number(data.userId) }), // ! FOR ADMIN
          ...(data.projectEstimators && { projectEstimators }), // ! FOR MANAGER
        },
      },
    });
  };

  const sendRequest = () => {
    const [
      name,
      status,
      isWithChart,
      recommendations,
      comments,
      aboutUs,
      userId,
    ] = watch([
      "name",
      "status",
      "isWithChart",
      "recommendations",
      "comments",
      "aboutUs",
      "userId",
    ]);

    onSubmit({
      name,
      status,
      isWithChart,
      recommendations,
      comments,
      aboutUs,
      userId,
    });
  };

  const handleCheckbox = () => {
    setIsChart((prev: boolean) => !prev);
    setValue("isWithChart", !getValues("isWithChart"));
    sendRequest();
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" onBlur={handleSubmit(onSubmit)}>
        <Container sx={styles.container} maxWidth="xl">
          {!isEstimator && (
            <>
              <Stack direction={"row"} margin={"12px 0 20px"} gap={"20px"}>
                <TextField
                  {...register("name")}
                  sx={styles.input}
                  label="Name Project"
                  variant="outlined"
                  helperText={errors.name && errors.name?.message}
                  error={!!errors?.name}
                  autoFocus
                />
                <SelectRHF
                  name={"status"}
                  options={PROJECT_STATUS}
                  sx={{ maxWidth: "130px", width: "130px", flex: 1 }}
                  label={"Project status"}
                />
              </Stack>
              <Stack direction={"row"} gap={"20px"} flexWrap={"wrap"}>
                {isAdmin && (
                  <SelectRHF
                    name={"userId"}
                    options={users}
                    label={"Select manager"}
                    extraText
                  />
                )}
                <FormProvider {...langMethods}>
                  <LanguagePicker
                    name={"language"}
                    options={data?.getLanguages || []}
                    sx={{ order: 2 }}
                  />
                </FormProvider>
                {(isManager || isAdmin) && (
                  <AutocompleteRHF
                    name={"projectEstimators"}
                    options={estimators}
                    extraText
                    sx={{
                      order: isManager ? 0 : 3,
                      ...(isAdmin && { flex: "1 1 100%" }),
                    }}
                  />
                )}
              </Stack>
              <Box sx={styles.checkbox}>
                <FormControlLabel
                  label="Нужна диаграмма?"
                  control={
                    <Checkbox
                      {...register("isWithChart")}
                      onChange={handleCheckbox}
                      checked={isChart}
                    />
                  }
                />
              </Box>
            </>
          )}
          <Box
            sx={styles.editorWrapper(
              Boolean(errors?.recommendations?.message)
            )}>
            <EditorController
              name="recommendations"
              control={control}
              label="Рекомендации"
            />
          </Box>
          <Box sx={styles.editorWrapper(Boolean(errors?.comments?.message))}>
            <EditorController
              name="comments"
              control={control}
              label="Комментарии"
            />
          </Box>
          <Box sx={styles.editorWrapper(Boolean(errors?.aboutUs?.message))}>
            <EditorController name="aboutUs" control={control} label="О нас" />
          </Box>
        </Container>
      </Box>
    </FormProvider>
  );
};

export default SettingsTab;
