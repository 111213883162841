import { createDragColumn } from "./createDragColumn";
import { createStartDateColumn } from "./createStartDateColumn";
import { createTaskNameColumn } from "./createTaskNameColumn";
import { createTotalHoursColumn } from "./createTotalHoursColumn";

export const ganttWithChart = [
  createDragColumn(),
  createTaskNameColumn(),
  createStartDateColumn(),
  createTotalHoursColumn(),
];

export const ganttWithoutChart = [
  createDragColumn(),
  createTaskNameColumn(),
  createTotalHoursColumn(),
];
