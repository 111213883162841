import React, { useState } from "react";
import { GridCellParams } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { useEditAttachedDeveloperInProjectMutation } from "graphql/generated";
import { GET_PROJECT } from "graphql/queries/getProject.gql";

interface RankCellProps {
  params: GridCellParams;
}

const RateCell: React.FC<RankCellProps> = ({ params }) => {
  const { id } = useParams();
  const [inputValue, setInputValue] = useState<number>(
    params.row.defaultRate || 0
  );
  const [editAttachedDeveloperInProjectMutation] =
    useEditAttachedDeveloperInProjectMutation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(Number(event.target.value));
  };

  const editDevelopersRate = async () => {
    if (!params.row.name) return;
    await editAttachedDeveloperInProjectMutation({
      variables: {
        projectId: Number(id),
        developerId: Number(params.id),
        input: {
          rank: params.row.defaultRank,
          rate: Number(inputValue),
        },
      },
      refetchQueries: () => [
        {
          query: GET_PROJECT,
          variables: {
            projectId: Number(id),
          },
        },
      ],
    });
  };

  return (
    <TextField
      label="Rate"
      variant="filled"
      onChange={handleChange}
      value={inputValue}
      onBlur={editDevelopersRate}
    />
  );
};

export default RateCell;
