export const extractStoryId = (complexStoryId = ""): number => {
  return Number(complexStoryId.replace("story-", ""));
};

export const extractTaskId = (complexTaskId = ""): number => {
  return Number(complexTaskId.replace("task-", ""));
};

export const extractSprintId = (complexSprintId = ""): number => {
  return Number(complexSprintId.replace("sprint-", ""));
};

// gantt.config.layout
export const LAYOUT_CONFIG = {
  css: "gantt_container",
  cols: [
    {
      rows: [
        {
          view: "grid",
          scrollX: "gridScroll",
          scrollable: true,
          scrollY: "scrollVer",
        },
        {
          view: "scrollbar",
          id: "gridScroll",
          scroll: "x",
          group: "hor",
        },
      ],
    },
    { resizer: true, width: 1 },
    {
      rows: [
        {
          view: "timeline",
          scrollX: "scrollHor",
          scrollY: "scrollVer",
        },
        {
          view: "scrollbar",
          id: "scrollHor",
          scroll: "x",
          group: "hor",
        },
      ],
    },
    {
      view: "scrollbar",
      id: "scrollVer",
    },
  ],
};

export const INITIAL_LAYOUT_CONFIG = {
  css: "gantt_container",
  rows: [
    {
      cols: [
        {
          view: "grid",
          scrollX: "scrollHor",
          scrollY: "scrollVer",
        },
        {
          resizer: true,
          width: 1,
        },
        {
          view: "timeline",
          scrollX: "scrollHor",
          scrollY: "scrollVer",
        },
        {
          view: "scrollbar",
          id: "scrollVer",
        },
      ],
    },
  ],
};
