import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Delete } from "@mui/icons-material";
import { deleteIconStyles, iconButtonSize } from "./styles";

interface TableActionsProps {
  params: any;
  removeProject: (id: number) => () => void;
}

const TableActions: React.FC<TableActionsProps> = ({
  params,
  removeProject,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <IconButton
        sx={{ ...iconButtonSize, ...deleteIconStyles }}
        onClick={removeProject(Number(params.id))}>
        <Delete />
      </IconButton>
    </Box>
  );
};

export default TableActions;
