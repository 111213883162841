export const postUploadFileThunk = async ({
  formData,
}: {
  formData: FormData;
}): Promise<Response> => {
  const token = localStorage.getItem("token");

  return await fetch(`${process.env.REACT_APP_URI}/api/file/uploadFile`, {
    method: "post",
    body: formData,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
};
