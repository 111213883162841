import React from "react";
import IconButton from "@mui/material/IconButton";
import { Delete } from "@mui/icons-material";
import { GridCellParams } from "@mui/x-data-grid";
import { deleteIconStyles } from "../ProjectsTable/styles";

interface TableActionsProps {
  params: GridCellParams;
  removeDeveloper: (id: string) => () => void;
}

const TableActions: React.FC<TableActionsProps> = ({
  params,
  removeDeveloper,
}) => {
  return (
    <IconButton
      onClick={removeDeveloper(params.id.toString())}
      sx={{ ...deleteIconStyles }}>
      <Delete />
    </IconButton>
  );
};

export default TableActions;
