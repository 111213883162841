import { useEffect } from "react";
import { Container, Typography, Box, TextField, Button } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { settingsSchema } from "validations/SettingsValidations/settingsSchema";
import { styles } from "screens/SettingsScreen/styles";
import { EditorController } from "components";
import {
  useChangeSettingsMutation,
  useGetSettingsQuery,
} from "graphql/generated/index";
import Layout from "components/Layout/Layout";
import { useAlertContext } from "context/AlertContext";

interface FormData {
  mail: string;
  phone: string;
  defaultRecommendations: string;
  defaultComments: string;
  defaultAboutUs: string;
}

const SettingsScreen = () => {
  const { data: initValue } = useGetSettingsQuery();

  const { handleChangeAlertParameters } = useAlertContext();

  const initSettings = initValue?.getSettings;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(settingsSchema),
  });

  const handleError = () => {
    handleChangeAlertParameters({
      isOpen: true,
      message: "Somethinh went wrong!",
      type: "error",
    });
  };

  useEffect(() => {
    if (initSettings) {
      reset({
        mail: initSettings?.mail,
        phone: initSettings?.phone,
        defaultRecommendations: initSettings?.defaultRecommendations,
        defaultComments: initSettings?.defaultComments,
        defaultAboutUs: initSettings?.defaultAboutUs,
      });
    }
  }, [initSettings]);

  const [changeSettingsMutation] = useChangeSettingsMutation({});

  const onSubmit = async (data: FormData) => {
    const res = await changeSettingsMutation({
      variables: {
        input: data,
      },
      onError: handleError,
    });

    if (res.data) {
      handleChangeAlertParameters({
        isOpen: true,
        message: "Settings was saved",
        type: "success",
      });
    }
  };

  return (
    <Layout namePage="Настройки">
      <Container sx={styles.container} maxWidth="xl">
        <Typography sx={styles.title} align="center" variant="h4">
          Settings
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={styles.inputContainer}>
            <TextField
              {...register("mail")}
              sx={styles.input}
              label="Email Address"
              variant="outlined"
              helperText={errors?.mail && errors?.mail?.message}
              error={!!errors?.mail}
              defaultValue
              InputLabelProps={{ shrink: true }}
              autoFocus
            />
            <TextField
              {...register("phone")}
              sx={styles.input}
              label="phone"
              variant="outlined"
              helperText={errors?.phone && errors?.phone?.message}
              error={!!errors?.phone}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box
            sx={styles.editorWrapper(
              errors?.defaultRecommendations &&
                errors?.defaultRecommendations?.message
            )}>
            <EditorController
              name="defaultRecommendations"
              control={control}
              label="Рекомендации"
            />
          </Box>
          <Box
            sx={styles.editorWrapper(
              errors?.defaultComments && errors?.defaultComments?.message
            )}>
            <EditorController
              name="defaultComments"
              control={control}
              label="Комментарии"
            />
          </Box>
          <Box
            sx={styles.editorWrapper(
              errors?.defaultAboutUs && errors?.defaultAboutUs?.message
            )}>
            <EditorController
              name="defaultAboutUs"
              control={control}
              label="О нас"
            />
          </Box>

          <Button sx={styles.button} variant="contained" type="submit">
            <Typography variant="button">Submit</Typography>
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

export default SettingsScreen;
