import { Task } from "../types";
import { StoryEntity } from "graphql/generated";
import clsx from "clsx";
import styles from "../index.module.css";

export const createTotalHoursColumn = () => ({
  name: "totalHours",
  label: "Hours",
  width: 70,
  align: "center",
  template: (task: Task) => {
    if (task.type === "task") {
      return `<div>
                  <i class="${clsx("material-icons", styles.muiIcon)}">
                      timelapse
                  </i>
                  ${task.duration}
               </div>`;
    }

    return `<div>
                  <i class="${clsx("material-icons", styles.muiIcon)}">
                      timelapse
                  </i>
                  ${(task as unknown as StoryEntity).totalHours}
               </div>`;
  },
});
