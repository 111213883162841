import styles from "./colors.module.css";

export const drawerWidth = 240;

export const projectName = "EstiMale";

export const limitItemsCount = 10;

export const itemsOffset = 5;

export const TYPE_MODAL = {
  CREATE_PROJECT: "create_project",
  DELETE: "delete",
  CREATE_USER: "create_user",
  EDIT_USER: "edit_user",
  CREATE_DEVELOPER: "create_developer",
  EDIT_DEVELOPER: "edit_developer",
  CREATE_SPRINT: "create_sprint",
  CREATE_STORY_TASK: "create_story_task",
  DELETE_SPRINT_STORY_TASK: "delete_sprint_story_task",
};

export const projectFieldTypes = {
  task: "task",
  story: "story",
  project: "project",
};

export const taskEntity = "TaskEntity";

export const alertTypes = {
  success: "success",
  error: "error",
};

export const dayFormat = "%j %D";

export const avatarColorSchema = [
  styles.light,
  styles.dark,
  styles.yellow,
  styles.green,
  styles.blue,
  styles.orange,
  styles.purple,
  styles.red,
  styles.brown,
  styles.national,
];

export const USER_STATUS = [
  { id: "1", value: "ACTIVE", label: "ACTIVE" },
  { id: "2", value: "INACTIVE", label: "INACTIVE" },
  { id: "3", value: "BANNED", label: "BANNED" },
];

export const PROJECT_STATUS = [
  { id: "1", value: "ACTIVE", label: "ACTIVE" },
  { id: "2", value: "ARCHIVED", label: "ARCHIVED" },
];

export const USER_ROLE = [
  { id: "1", value: "MANAGER", label: "MANAGER" },
  { id: "2", value: "ADMIN", label: "ADMIN" },
  { id: "3", value: "ESTIMATOR", label: "ESTIMATOR" },
];
