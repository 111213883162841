import React from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { saveAs } from "file-saver";
import { GridCellParams } from "@mui/x-data-grid";

interface TableActionsProps {
  params: GridCellParams;
}

const ActionsTable: React.FC<TableActionsProps> = ({ params }) => {
  const viewPDF = () => {
    saveAs(
      `${process.env.REACT_APP_URI}/uploads/files/${params.row.name}`,
      "estimale.pdf"
    );
  };

  return (
    <IconButton onClick={viewPDF}>
      <RemoveRedEyeOutlinedIcon />
    </IconButton>
  );
};

export default ActionsTable;
