import React, { useContext, useState } from "react";

export const AlertContext = React.createContext<any>("");

export function useAlertContext() {
  const alertContext = useContext(AlertContext);
  if (!alertContext) {
    throw new Error("useAlertContext must be used within a AuthProvider");
  }

  return alertContext;
}

export const AlertContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [alertParameters, setAlertParameters] = useState<paramsType>({
    isOpen: false,
    type: "success",
    statusCode: NaN,
    message: "",
    position: "top",
  });

  type paramsType = {
    isOpen: boolean;
    type: "success" | "error";
    statusCode?: number;
    message: string;
    position: "top" | "bottom";
  };

  const handleChangeAlertParameters = (params: paramsType) => {
    setAlertParameters((prevAlertParameters: paramsType) => ({
      ...prevAlertParameters,
      ...params,
    }));
  };

  return (
    <AlertContext.Provider
      value={{
        isAuth: false,
        handleChangeAlertParameters: handleChangeAlertParameters,
        alertParameters: alertParameters,
      }}>
      {children}
    </AlertContext.Provider>
  );
};
