export const createDragColumn = () => ({
  name: "reordering",
  label: "Drag",
  width: 40,
  height: 20,
  align: "center",
  template: () => {
    return `<div class="wrapperIcon">
                <i class="material-icons click-drag">apps</i>
            </div>`;
  },
});
