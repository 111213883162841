import * as yup from "yup";
import { messages } from "validations/messages";

export const developerSchemaCreate = yup.object().shape({
  name: yup.string().required(),
  defaultRank: yup.string().required(),
  status: yup.string(),
  defaultRate: yup
    .number()
    .test(messages.RATE_VALUE, (value) => (value || 0) > 0)
    .required(),
});
