import { useCallback, useEffect, useRef, FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import { styles } from "components/Alert/AlertMessage/styles";
import { useAlertContext } from "context/AlertContext";

const AlertMessage: FC = () => {
  const { alertParameters, handleChangeAlertParameters } = useAlertContext();
  const { isOpen, type, position, message } = alertParameters;

  const timeoutReference = useRef<ReturnType<typeof setTimeout> | undefined>();

  const handleCloseAlert = useCallback(
    () =>
      handleChangeAlertParameters({
        isOpen: false,
      }),
    []
  );

  useEffect(() => {
    if (isOpen) {
      timeoutReference.current = setTimeout(() => {
        handleCloseAlert();
      }, 5000);
    } else if (timeoutReference.current) {
      clearTimeout(timeoutReference.current);
    }
  }, [isOpen]);

  return (
    <Collapse in={isOpen} sx={styles.alert(position)}>
      <Alert
        severity={type}
        action={
          <IconButton
            aria-label="close"
            color={type}
            size="small"
            onClick={handleCloseAlert}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}>
        {message}
      </Alert>
    </Collapse>
  );
};

export default AlertMessage;
