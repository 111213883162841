import { useState, useEffect, MouseEventHandler } from "react";
import { useDropzone, DropzoneInputProps, DropEvent } from "react-dropzone";
import * as S from "./styles";
import Avatar from "@mui/material/Avatar";

interface File {
  path?: string;
  preview?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  name?: string;
  size?: number;
  type?: string;
  webkitRelativePath?: string;
}

interface DropzoneRHFProps {
  setRHFvalue: (key: any, value: File | null | string) => void;
  onChange: (event: any[]) => void;
  avatarName: string;
  name: string;
  nameFieldToNullable?: string;
}

const DropzoneRHF: React.FC<DropzoneRHFProps> = ({
  setRHFvalue,
  onChange,
  avatarName,
  name,
  nameFieldToNullable,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [avatar, setAvatar] = useState<string>(avatarName);

  const clearAvatar = () => {
    setAvatar("");
    setRHFvalue(name, null);
    nameFieldToNullable && setRHFvalue(nameFieldToNullable, null);
  };

  const clearSelectedAvatar = () => {
    setSelectedFile(null);
  };

  const handleClick = (e: Event) => {
    e.stopPropagation();
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragAccept } =
    useDropzone({
      onDrop: (files: File[], _, event: DropEvent) => {
        event.stopPropagation();
        const file: File = files[0];
        setSelectedFile(
          Object.assign(file, {
            preview: URL.createObjectURL(file as File as Blob | MediaSource),
          })
        );
        setRHFvalue(name, files[0]);
      },
      multiple: false,
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "image/jfif": [],
        "image/jpg": [],
        "image/jpe": [],
      },
    });

  useEffect(() => {
    setSelectedFile(acceptedFiles[0]);
  }, [acceptedFiles]);

  return (
    <>
      {selectedFile ? (
        <S.SelectedFileContainer>
          <S.ClearFile onClick={clearSelectedAvatar} />
          <S.SelectedFile>{selectedFile.path}</S.SelectedFile>
          <Avatar src={selectedFile.preview} sx={S.img} />
        </S.SelectedFileContainer>
      ) : avatar ? (
        <S.SelectedFileContainer>
          <S.ClearFile onClick={clearAvatar} />
          <S.SelectedFile>{avatar}</S.SelectedFile>
          <Avatar
            src={`${process.env.REACT_APP_URI}/uploads/avatars/${avatarName}`}
            sx={S.img}
          />
        </S.SelectedFileContainer>
      ) : (
        <>
          <S.Dropzone
            {...getRootProps({
              onClick: handleClick as unknown as MouseEventHandler<HTMLElement>,
            })}>
            <p>Drag n drop file here, or click to select file</p>
            <input
              type="file"
              name="file"
              accept={"image/png, image/jpg, image/jpeg, image/jpe, image/jfif"}
              {...getInputProps(onChange as unknown as DropzoneInputProps)}
            />
          </S.Dropzone>
        </>
      )}
    </>
  );
};

export default DropzoneRHF;
