export const palette = {
  text: {
    primary: "#393939",
    secondary: "#9c9c9c",
  },
  primary: {
    main: "#0f59ff",
    dark: "#0f59ff",
  },
  error: {
    main: "#F46E6E",
  },
};
