import * as yup from "yup";

const minPasswordLength = 8;

export const userSchemaSignIn = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(minPasswordLength).required(),
});

export const userSchemaPasswordReset = yup.object().shape({
  email: yup.string().email().required(),
});

export const userSchemaPasswordResetConfirm = yup.object().shape({
  password: yup.string().min(minPasswordLength).required(),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
});
