import { useState } from "react";
import Layout from "components/Layout/Layout";
import { useGetDevelopersListQuery } from "graphql/generated";
import DevelopersTable from "components/DevelopersTable";
import { useRemoveDeveloperMutation } from "graphql/generated";
import { alertTypes, limitItemsCount, TYPE_MODAL } from "constants/constants";
import { useAlertContext } from "context/AlertContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useModalContext } from "context/ModalContext";
import { styles } from "screens/DevelopersScreen/styles";
import Icon from "@mui/material/Icon";
import AddIcon from "@mui/icons-material/Add";

export default function DevelopersScreen() {
  const [pageNumberState, setPageNumberState] = useState<number>(0);
  const { data, refetch } = useGetDevelopersListQuery({
    variables: {
      offset: pageNumberState * limitItemsCount,
      limit: limitItemsCount,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  const { handleChangeModalParameters } = useModalContext();
  const { handleChangeAlertParameters } = useAlertContext();

  const rows = data?.getDevelopersList.developers || [];
  const count = data?.getDevelopersList.count || 0;

  const [removeDeveloper] = useRemoveDeveloperMutation();

  const handleOpenModalCreate = () => {
    handleChangeModalParameters({
      isOpen: true,
      type: TYPE_MODAL.CREATE_DEVELOPER,
      cancelAction: handleCloseModal,
      params: {
        pageNumber: pageNumberState,
        setPageNumberState,
      },
    });
  };

  const handleCloseModal = () => {
    handleChangeModalParameters({
      isOpen: false,
    });
  };

  const handleOpenModalDelete = (id: string) => () => {
    handleChangeModalParameters({
      isOpen: true,
      type: TYPE_MODAL.DELETE,
      cancelAction: handleCloseModal,
      confirmAction: () => deleteDeveloper(id),
      params: {
        name: "developer",
      },
    });
  };

  const handleErrors = (errors: any) => {
    handleCloseModal();

    handleChangeAlertParameters({
      isOpen: true,
      message: errors.message,
      type: alertTypes.error,
    });
  };

  const deleteDeveloper = async (id: string) => {
    const res = await removeDeveloper({
      variables: {
        id,
      },
      onError: handleErrors,
    });
    await refetch({
      offset: pageNumberState * limitItemsCount,
      limit: limitItemsCount,
    });

    if (res.data && res.data.removeDeveloper.status) {
      handleChangeAlertParameters({
        isOpen: true,
        message: "Developer was deleted",
        type: alertTypes.success,
      });
    } else {
      handleChangeAlertParameters({
        isOpen: true,
        message: res.data?.removeDeveloper.message || "Нельзя удалить",
        type: alertTypes.error,
      });
    }

    handleCloseModal();
  };

  return (
    <Layout namePage="Разработчики">
      <Box sx={styles.container}>
        <Button
          variant="contained"
          onClick={handleOpenModalCreate}
          sx={styles.buttonCreate}>
          <Icon sx={styles.buttonIcon}>
            <AddIcon />
          </Icon>
          New Developer
        </Button>
        <DevelopersTable
          rows={rows}
          rowCount={count}
          removeDeveloper={handleOpenModalDelete}
          setPageNumberState={setPageNumberState}
          page={pageNumberState}
        />
      </Box>
    </Layout>
  );
}
