import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styles } from "screens/AuthScreens/styles";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userSchemaPasswordReset } from "validations/AuthValidations";
import { useRequestPasswordResetMutation } from "graphql/generated";
import { useNavigate } from "react-router-dom";
import { useAlertContext } from "context/AlertContext";
import { alertTypes } from "../../../constants";

interface FormData {
  email: string;
}

const PasswordResetScreen: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(userSchemaPasswordReset),
  });
  const navigate = useNavigate();

  const [passwordReset] = useRequestPasswordResetMutation();

  const { handleChangeAlertParameters } = useAlertContext();

  const handleErrors = (error: any) => {
    const errorObj = error.graphQLErrors[0];
    if (errorObj[0].status > 400) {
      handleChangeAlertParameters({
        isOpen: true,
        message: errorObj[0].message,
        type: alertTypes.error,
      });
    } else {
      setError(errorObj[0].field, {
        type: "custom",
        message: errorObj[0].message,
      });
    }
  };

  const onSubmit = async (data: FormData) => {
    const res = await passwordReset({
      variables: {
        email: data.email,
      },
      onError: handleErrors,
    });

    if (res.data && res.data.requestPasswordReset.status === 200) {
      navigate("/");
      handleChangeAlertParameters({
        isOpen: true,
        message: "A password change link has been sent to your e-mail address.",
        type: alertTypes.success,
      });
    }
  };

  return (
    <Container sx={styles.container} maxWidth="xl">
      <Typography sx={styles.title} align="center" variant="h4">
        Password reset
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.inputContainer}>
          <TextField
            {...register("email")}
            sx={styles.input}
            label="Email Adress"
            variant="outlined"
            helperText={errors?.email && errors?.email?.message}
            error={!!errors?.email}
          />
        </Box>
        <Button sx={styles.button} variant="contained" type="submit">
          <Typography variant="button">Password reset</Typography>
        </Button>
      </Box>
      <Box sx={styles.linksContainer}>
        <Link to="/">
          <Typography variant="body2">
            Do you have an account? Sign In
          </Typography>
        </Link>
      </Box>
    </Container>
  );
};

export default PasswordResetScreen;
