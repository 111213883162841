import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { advancedGantt } from "./ganttFactory";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import "./Gantt.css";
import { ganttWithChart, ganttWithoutChart } from "./columns";
import {
  useEditTask,
  useMoveTask,
  useSortSprint,
  useSortStory,
  useSortTask,
} from "graphql/hooks";
import { useModalContext } from "context/ModalContext";
import { projectFieldTypes, TYPE_MODAL } from "constants/constants";
import { adjustEndTime, reorderArray } from "utils";
import { createDevelopersColumn } from "./columns/createDevelopersColumn";
import { createActionButtonsColumn } from "./columns/createActionButtonsColumn";
import {
  gridBlankCallback,
  gridFileCallback,
  gridFolderCallback,
  gridOpenCallback,
  taskClassCallback,
  taskTextCallback,
  timelineCellClassCallback,
} from "./templateCallbacks";
import { handleTaskDblClick, handleToggleTree } from "./eventHandlers";
import Box from "@mui/material/Box";
import { GanttProps, NewTaskType } from "./types";
import {
  extractSprintId,
  extractStoryId,
  extractTaskId,
  INITIAL_LAYOUT_CONFIG,
  LAYOUT_CONFIG,
} from "./utils";
import { MoveTaskToEnum } from "graphql/generated";
import { useMoveStory } from "graphql/hooks/useMoveStory";
import { getStyles } from "./styles";
import { getRoundTimeToHour } from "../../utils";

const Gantt: FC<GanttProps> = ({
  inputData,
  isChart,
  developers,
  projectId,
  sprintsOrder,
  sprints,
  refetchProjectData,
}) => {
  const ganttContainer = useRef<HTMLElement>();
  const { handleChangeModalParameters, modalParameters } = useModalContext();
  const isOpenModal = modalParameters.isOpen;
  const scrollX = modalParameters?.params?.posX;

  const [newTask, setNewTask] = useState<Array<NewTaskType>>([]);

  const [sortSprint] = useSortSprint(projectId);
  const [sortStory] = useSortStory(projectId);
  const [sortTask] = useSortTask(projectId);
  const [editTask] = useEditTask({
    withNotification: false,
    projectId: projectId,
  });
  const [moveTask] = useMoveTask(projectId);
  const [moveStory] = useMoveStory(projectId);

  const columnsDevelopersAndActions = [
    createDevelopersColumn({ isChart }),
    createActionButtonsColumn(),
  ];

  const handleTaskClick = (id: string, e: ChangeEvent<HTMLElement>) => {
    const button = e.target.closest("[data-action]");

    if (button) {
      const action = button.getAttribute("data-action");

      if (action === "toggle_tree") {
        handleToggleTree(e, id);
      }

      if (action === "add") {
        const sprintId = button.getAttribute("data-sprintId");
        const storyId = button.getAttribute("data-storyId");
        const taskType = button.getAttribute("data-type");

        const dataSprint = gantt.getTask(`sprint-${sprintId}`);
        const endTime = dataSprint?.end_date;
        const startTime = dataSprint?.start_date;

        const indexTargetSprint = sprints.findIndex(
          (sprint) => sprint.id === sprintId
        );

        const isStoriesAndTasksForSprintId = Boolean(
          sprints[indexTargetSprint]?.storiesAndTasks?.length
        );

        const prevDataSprint =
          sprints[indexTargetSprint - 1]?.id &&
          gantt.getTask(`sprint-${sprints[indexTargetSprint - 1]?.id}`);

        const prevEndTime = prevDataSprint?.end_date;

        const isNullableStartAtTargetSprint =
          !!sprints[indexTargetSprint]?.startedAt;

        const getTomorrowDay = () => {
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + 1);
          currentDate.setHours(8, 0, 0);
          return currentDate;
        };
        const tomorrowDay = getTomorrowDay();

        const getActualEndTime = () => {
          if (isStoriesAndTasksForSprintId) {
            return endTime;
          } else if (!isNullableStartAtTargetSprint) {
            if (!prevEndTime) {
              return tomorrowDay;
            } else {
              return prevEndTime;
            }
          } else {
            return startTime;
          }
        };

        const actualEndTime = getActualEndTime();

        const adjustedEndTime = adjustEndTime(actualEndTime);

        handleChangeModalParameters({
          isOpen: true,
          type: TYPE_MODAL.CREATE_STORY_TASK,
          params: {
            taskType,
            sprintId,
            storyId,
            isChart,
            developers,
            projectId: projectId,
            refetchProjectData,
            defaultValues: {
              startedAt: adjustedEndTime,
            },
          },
        });
      }

      if (action === "edit") {
        const sprintId = button.getAttribute("data-sprintId");
        const storyId = button.getAttribute("data-storyId");

        const taskData = advancedGantt.getTask(id);

        if (id.startsWith("sprint")) {
          const sprintId = extractSprintId(id);

          handleChangeModalParameters({
            type: TYPE_MODAL.CREATE_SPRINT,
            isOpen: true,
            params: {
              id: sprintId,
              refetchProjectData,
              isChangeName: true,
              defaultValues: {
                name: taskData.name,
              },
            },
          });
        }
        if (id.startsWith("story")) {
          handleChangeModalParameters({
            isOpen: true,
            type: TYPE_MODAL.CREATE_STORY_TASK,
            params: {
              taskType: "project",
              sprintId,
              storyId,
              isChart,
              developers,
              projectId: projectId,
              refetchProjectData,
              isChangeName: true,
              defaultValues: {
                name: taskData.name,
              },
            },
          });
        }
        if (id.startsWith("task")) {
          handleChangeModalParameters({
            isOpen: true,
            type: TYPE_MODAL.CREATE_STORY_TASK,
            params: {
              // taskType must be a because in order to show task modal.
              // Dirty hack. Need to refactor in the future
              taskType: "story",
              sprintId,
              storyId,
              isChart,
              developers,
              taskId: extractTaskId(id),
              projectId: projectId,
              refetchProjectData,
              defaultValues: {
                name: taskData.name,
                startedAt: new Date(Number(taskData.startedAt)),
                duration: taskData.duration,
                developerId: taskData.developerId,
              },
            },
          });
        }
      }

      if (action === "delete") {
        const taskType = button.getAttribute("data-type");
        const taskId = button.getAttribute("data-id");

        handleChangeModalParameters({
          isOpen: true,
          type: TYPE_MODAL.DELETE_SPRINT_STORY_TASK,
          params: {
            taskType,
            taskId,
            projectId: id,
            refetchProjectData,
          },
        });
      }
    }
  };

  const handleMoveSprint = ({
    parent,
    tIndex,
    sprintId,
  }: {
    parent: string;
    tIndex: number;
    sprintId: string;
  }) => {
    if (Number(parent) !== 0) return false;

    const movingElementPosition = sprintsOrder.indexOf(
      extractSprintId(sprintId)
    );
    const newSprintsOrder = reorderArray(
      sprintsOrder,
      movingElementPosition,
      tIndex
    );
    const checkSprints = newSprintsOrder.filter((sprint) => !!sprint);

    if (checkSprints.length) {
      sortSprint({
        variables: {
          sprintIds: newSprintsOrder,
        },
      });
    }
  };

  const handleMoveStory = ({
    parent,
    taskOldParent,
    tIndex,
    storyId,
  }: {
    parent: string;
    taskOldParent: string;
    tIndex: number;
    storyId: string;
  }) => {
    const idStory = extractStoryId(storyId);

    if (
      Number(parent) === 0 ||
      parent.startsWith("story") ||
      parent.startsWith("task")
    ) {
      return false;
    }

    const parentSprintId = extractSprintId(parent);

    // if reordering in context of the same sprint
    if (taskOldParent === parent) {
      sortStory({
        variables: {
          input: {
            storyId: idStory,
            sortOrder: tIndex,
          },
        },
      });
    } else {
      moveStory({
        variables: {
          input: {
            storyId: idStory,
            newSprintId: parentSprintId,
            sortOrder: tIndex,
          },
        },
      });
    }
  };

  const handleMoveTask = ({
    parent,
    taskOldParent,
    tIndex,
    taskId,
  }: {
    parent: string;
    taskOldParent: string;
    tIndex: number;
    taskId: string;
  }) => {
    const idTask = extractTaskId(taskId);

    if (Number(parent) === 0 || parent.startsWith("task") || !sprints.length) {
      return false;
    }

    if (parent.startsWith("story")) {
      // if user reorders tasks in context of the same story
      if (parent === taskOldParent) {
        sortTask({
          variables: {
            input: {
              taskId: idTask,
              sortOrder: tIndex,
            },
          },
        });
      } else {
        const storyId = extractStoryId(parent);

        moveTask({
          variables: {
            input: {
              taskId: idTask,
              moveTo: MoveTaskToEnum.Story,
              moveToId: storyId,
              sortOrder: tIndex,
            },
          },
        });
      }
    } else {
      // if parent of movable item is sprint instance

      const sprintId = extractSprintId(parent);

      moveTask({
        variables: {
          input: {
            taskId: idTask,
            moveTo: MoveTaskToEnum.Sprint,
            moveToId: sprintId,
            sortOrder: tIndex,
          },
        },
      });
    }
  };

  const handleBeforeRowDragEnd = (
    id: string,
    parent: string,
    tIndex: number
  ) => {
    const task = advancedGantt.getTask(id);
    const taskOldParent = advancedGantt.getParent(id) as string;

    // moving sprint
    if (task.type === projectFieldTypes.project) {
      handleMoveSprint({ parent, tIndex, sprintId: id });
    }

    // moving story
    if (task.type === projectFieldTypes.story) {
      const parentTask = advancedGantt.getTask(parent);
      if (parentTask && parentTask.type === projectFieldTypes.story) {
        return false;
      }
      handleMoveStory({ tIndex, parent, taskOldParent, storyId: id });
    }

    // moving task
    if (task.type === projectFieldTypes.task) {
      handleMoveTask({ tIndex, parent, taskOldParent, taskId: id });
    }

    return true;
  };

  useEffect(() => {
    const columns = [
      ...(isChart ? ganttWithChart : ganttWithoutChart),
      ...columnsDevelopersAndActions,
    ];

    advancedGantt.config.layout = isChart
      ? LAYOUT_CONFIG
      : INITIAL_LAYOUT_CONFIG;

    advancedGantt.setColumns(columns);

    advancedGantt.setTemplate("task_class", taskClassCallback);
    advancedGantt.setTemplate("task_text", taskTextCallback);
    advancedGantt.setTemplate("grid_folder", gridFolderCallback);
    advancedGantt.setTemplate("grid_open", gridOpenCallback);
    advancedGantt.setTemplate("grid_file", gridFileCallback);
    advancedGantt.setTemplate("grid_blank", gridBlankCallback);
    advancedGantt.setTemplate("timeline_cell_class", timelineCellClassCallback);

    advancedGantt.attachEvent("onTaskDblClick", handleTaskDblClick, undefined);
    advancedGantt.attachEvent("onTaskClick", handleTaskClick, undefined);
    const idHandleBeforeRowDragEnd = advancedGantt.attachEvent(
      "onBeforeRowDragEnd",
      handleBeforeRowDragEnd,
      undefined
    );

    advancedGantt.attachEvent(
      "onTaskDrag",
      function (id, mode, task) {
        if (task.type === "task") {
          const tempTask = {
            variables: {
              taskId: Number(task.ID),
              input: {
                name: task.name,
                duration: task.duration,
                startedAt: getRoundTimeToHour(task.start_date),
                developerId: Number(task.developerId),
              },
            },
          };

          setNewTask((prev) => {
            let tempArray = [...prev];
            if (!tempArray.length) {
              tempArray.push(tempTask);
            } else {
              //check equality id
              const newArr = tempArray?.filter(
                (elem) => elem?.variables?.taskId === Number(task.ID)
              );

              if (!newArr.length) {
                tempArray.push(tempTask);
              } else {
                const myValue = tempArray.map((elem) => {
                  let tempElem = { ...elem };
                  if (elem?.variables?.taskId === Number(task.ID)) {
                    //re-write startedAt and duration fields
                    tempElem = {
                      ...elem,
                      ...{
                        variables: {
                          ...elem.variables,
                          input: {
                            ...elem.variables.input,
                            startedAt: tempTask.variables.input.startedAt,
                            duration: tempTask.variables.input.duration,
                          },
                        },
                      },
                    };
                  }
                  return tempElem;
                });
                tempArray = [...myValue];
              }
            }

            return [...tempArray];
          });
        }
      },
      undefined
    );

    // drag and drop will work when user grabs the icon
    gantt.attachEvent("onRowDragStart", function (id, target, e) {
      return !!e.target.className.includes("click-drag");
    });

    gantt.config.order_branch_free = true;
    advancedGantt.config.drag_move = true;
    gantt.config.drag_links = false;
    advancedGantt.config.order_branch = "marker";
    advancedGantt.config.autosize = "y";
    advancedGantt.config.reorder_grid_columns = true;
    advancedGantt.config.row_height = 45;
    advancedGantt.config.scale_height = 50;

    advancedGantt.config.types.story = "story";
    advancedGantt.locale.labels.type_story = "Story";
    gantt.config.drag_project = true;

    advancedGantt.config.work_time = true;
    advancedGantt.config.correct_work_time = true;
    advancedGantt.setWorkTime({ hours: ["8:00-16:00"] });

    // === шаг времени в 1 час
    advancedGantt.config.step = 1;
    // позволяет округлять даты начала и окончания задачи до ближайших отметок масштаба
    advancedGantt.config.round_dnd_dates = false;
    // ===

    advancedGantt.config.scroll_size = 20;

    if (ganttContainer.current) {
      // initializes a dhtmlxGantt inside a container
      advancedGantt.init(ganttContainer.current);
    }
    // loads data from a client-side resource
    advancedGantt.parse(inputData);

    advancedGantt.setScales(inputData.tasks);
    advancedGantt.render();

    return () => {
      advancedGantt.detachEvent(idHandleBeforeRowDragEnd);
    };
  }, [inputData, developers, isChart]);

  useEffect(() => {
    // removes all tasks and additional elements (including markers) from the Gantt chart
    return () => {
      advancedGantt.clearAll();
    };
  }, []);

  useEffect(() => {
    const idOnAfterTaskDrag = gantt.attachEvent("onAfterTaskDrag", function () {
      if (newTask?.length) {
        newTask.forEach((task) => editTask(task));
        setNewTask([]);
      }
    });

    return () => {
      advancedGantt.detachEvent(idOnAfterTaskDrag);
    };
  }, [newTask]);

  useEffect(() => {
    localStorage.setItem("posX", "0");
    gantt.attachEvent("onGanttScroll", function (scrollX) {
      if (!scrollX) return;
      localStorage.setItem("posX", scrollX);
    });
  }, []);

  useEffect(() => {
    const posX = localStorage.getItem("posX");
    gantt.scrollTo(scrollX || posX, 0);
  }, [sprints, isOpenModal]);

  return (
    <Box
      className={isChart ? "" : "hide"}
      ref={ganttContainer}
      sx={getStyles({ isChart }).ganttContainer}
    />
  );
};

export default Gantt;
