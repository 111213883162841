export const styles = {
  container: {
    width: "35vw",
    padding: "8px",
    marginTop: "10%",
    position: "relative",
  },
  title: {
    marginBottom: "24px",
  },
  inputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    margin: "8px 0px 8px 0px",
  },
  button: {
    width: "100%",
  },
  linksContainer: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  footerText: {
    marginTop: "48px",
    color: "rgba(0, 0, 0, 0.6)",
  },
};
