import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone";
import { Controller, useFormContext } from "react-hook-form";
import {
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";
import { getChipVariant, getColorStatus } from "utils";
import {
  IAutocompleteCustomProps,
  IAutocompleteRHFProps,
  OptionType,
} from "./types";

const icon = <CheckBoxOutlineBlankTwoToneIcon />;
const checkedIcon = <CheckBoxTwoToneIcon />;

export const AutocompleteCustom = ({
  value,
  onChange,
  options,
  label,
  errorMessage,
  placeholder,
  mb,
  sx,
  extraText,
}: IAutocompleteCustomProps) => {
  return (
    <FormControl sx={{ marginBottom: mb, flex: 1, ...sx }}>
      {label ? (
        <FormLabel focused={false}>
          <Typography mb={1}>{label}</Typography>
        </FormLabel>
      ) : null}
      <Autocomplete
        multiple
        options={options}
        disableCloseOnSelect
        onChange={(evt, value) => onChange(value)}
        value={value}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderTags={(value: readonly OptionType[], getTagProps) =>
          value.map((option: OptionType, index: number) => {
            return (
              <Chip
                variant={getChipVariant(option.status)}
                label={option.label}
                {...getTagProps({ index })}
                key={option.id}
              />
            );
          })
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              sx={{ marginRight: 1 }}
              checked={selected}
            />
            <Typography>{option?.label}</Typography>
            {extraText && (
              <Typography color={getColorStatus(option?.status)}>
                &nbsp; - &nbsp;{option?.status.toLowerCase()}
              </Typography>
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            error={!!errorMessage}
            {...params}
            label=""
            placeholder={placeholder}
          />
        )}
      />
      {!!errorMessage && (
        <FormHelperText error>{String(errorMessage)}</FormHelperText>
      )}
    </FormControl>
  );
};

export const AutocompleteRHF = ({ name, ...rest }: IAutocompleteRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <AutocompleteCustom
            errorMessage={formState.errors?.[name]?.message}
            {...rest}
            value={field.value}
            onChange={field.onChange}
          />
        );
      }}
    />
  );
};
