import React from "react";
import Typography from "@mui/material/Typography";

interface ModalTitleProps {
  children: string;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ children }) => {
  return (
    <Typography
      align="center"
      id="modal-modal-title"
      variant="h6"
      component="h2">
      {children}
    </Typography>
  );
};

export default ModalTitle;
