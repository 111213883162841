/* eslint-disable max-len */
import React from "react";
import { Modal, Box } from "@mui/material";
import { useModalContext } from "context/ModalContext";
import { TYPE_MODAL } from "constants/constants";
import ModalDelete from "components/ModalController/ModalDelete";
import ModalCreateProject from "components/ModalController/ModalCreateProject";
import { styles } from "components/ModalController/styles";
import ModalCreateEditDeveloper from "components/ModalController/ModalCreateEditDeveloper";
import ModalCreateEditUser from "components/ModalController/ModalCreateEditUser";
import ModalCreateSprint from "components/ModalController/ModalCreateSprint";
import ModalCreateStoryTask from "components/ModalController/ModalCreateStoryTask";
import ModalDeleteSprintStoryTask from "components/ModalController/ModalDeleteSprintStoryTask";

export const ModalController = () => {
  const { modalParameters } = useModalContext();
  switch (modalParameters.type) {
    case TYPE_MODAL.DELETE:
      return <ModalDelete open={modalParameters.isOpen} />;
    case TYPE_MODAL.CREATE_PROJECT:
      return <ModalCreateProject open={modalParameters.isOpen} />;
    case TYPE_MODAL.CREATE_DEVELOPER:
      return <ModalCreateEditDeveloper open={modalParameters.isOpen} />;
    case TYPE_MODAL.EDIT_DEVELOPER:
      return <ModalCreateEditDeveloper open={modalParameters.isOpen} />;
    case TYPE_MODAL.CREATE_USER:
      return <ModalCreateEditUser open={modalParameters.isOpen} />;
    case TYPE_MODAL.EDIT_USER:
      return <ModalCreateEditUser open={modalParameters.isOpen} />;
    case TYPE_MODAL.CREATE_SPRINT:
      return <ModalCreateSprint open={modalParameters.isOpen} />;
    case TYPE_MODAL.CREATE_STORY_TASK:
      return <ModalCreateStoryTask open={modalParameters.isOpen} />;
    case TYPE_MODAL.DELETE_SPRINT_STORY_TASK:
      return <ModalDeleteSprintStoryTask open={modalParameters.isOpen} />;

    default:
      return <div />;
  }
};

export const BasicModal = () => {
  const { modalParameters, handleChangeModalParameters } = useModalContext();

  const handleCloseModal = () =>
    handleChangeModalParameters({
      isOpen: false,
    });

  return (
    <Modal
      open={modalParameters?.isOpen}
      onClose={handleCloseModal}
      sx={styles.container}>
      <Box sx={styles.modalControllerBox}>
        <ModalController />
      </Box>
    </Modal>
  );
};
