import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  type ApolloLink as ApolloLinkType,
} from "@apollo/client";
import authLink from "./authLink";
import httpLink from "./httpLink";
import errorLink from "./errorLink";

export const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink as unknown as ApolloLinkType,
    httpLink,
  ]),
  cache: new InMemoryCache(),
});
