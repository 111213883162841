export function getRoundTimeToHour(startedAt: Date) {
  // Возвращаем newDate, если данные некорректны
  if (!startedAt) return new Date();

  const startDate = new Date(startedAt);
  const minutes = startDate.getMinutes();

  // Округляем в ближайшую сторону
  if (minutes >= 30) {
    startDate.setHours(startDate.getHours() + 1);
  }

  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  return startDate;
}
