import React, { useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { limitItemsCount } from "constants/index";
import ActionsTable from "components/ProjectDevelopersTable/ActionsTable";
import { DeveloperEntity } from "graphql/generated";
import NameCell from "components/ProjectDevelopersTable/NameCell";
import { GridCellParams } from "@mui/x-data-grid";
import { Developer } from "screens/ProjectDetailsScreen/types";
import RankCell from "components/ProjectDevelopersTable/RankCell";
import RateCell from "components/ProjectDevelopersTable/RateCell";

interface ProjectDeveloperTableProps {
  rows: Developer[];
  count: number;
  allDevelopers: Array<Partial<DeveloperEntity>>;
  setDevelopers: (value: any) => void;
}

const ProjectDeveloperTable: React.FC<ProjectDeveloperTableProps> = ({
  rows,
  count,
  allDevelopers,
  setDevelopers,
}) => {
  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 220,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <NameCell {...{ params }} allDevelopers={allDevelopers} />
        ),
      },
      {
        field: "defaultRank",
        headerName: "Rank",
        width: 220,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => <RankCell {...{ params }} />,
      },
      {
        field: "defaultRate",
        headerName: "Rate",
        width: 100,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => <RateCell {...{ params }} />,
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params: GridCellParams) => {
          return params.row.name ? (
            <ActionsTable
              {...{ params }}
              developers={rows}
              setDevelopers={setDevelopers}
            />
          ) : (
            <></>
          );
        },
        width: 20,
        minWidth: 100,
        filterable: false,
        sortable: false,
      },
    ],
    [allDevelopers, rows]
  );

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={limitItemsCount}
      rowCount={count}
      checkboxSelection={false}
      autoHeight
      autoPageSize
      disableSelectionOnClick
      disableColumnMenu
    />
  );
};

export default ProjectDeveloperTable;
