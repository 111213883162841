import React, { useState } from "react";
import { GridCellParams } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  DeveloperEntity,
  useEditAttachedDeveloperInProjectMutation,
  useAttachDeveloperToProjectMutation,
  GetProjectCustomDocument,
} from "graphql/generated";
import { useParams } from "react-router-dom";

interface NameCellProps {
  params: GridCellParams;
  allDevelopers: Array<Partial<DeveloperEntity>>;
}

interface EditResponce {
  newDeveloperId: string;
  defaultRank?: string;
  defaultRate?: number;
  name?: string;
}

const NameCell: React.FC<NameCellProps> = ({ params, allDevelopers }) => {
  const [name, setName] = useState<string | undefined>(params.row.name);
  const { id } = useParams();
  const [editAttachedDeveloperInProjectMutation] =
    useEditAttachedDeveloperInProjectMutation();
  const [attachDeveloperToProjectMutation] =
    useAttachDeveloperToProjectMutation();

  const editDeveloper = async ({
    newDeveloperId,
    defaultRank,
    defaultRate,
  }: EditResponce) => {
    await editAttachedDeveloperInProjectMutation({
      variables: {
        projectId: Number(id),
        developerId: Number(params.id),
        input: {
          rank: defaultRank as string,
          rate: defaultRate as number,
          newDeveloperId: Number(newDeveloperId),
        },
      },
      refetchQueries: [GetProjectCustomDocument],
    });
  };

  const attachDeveloper = async ({ newDeveloperId }: EditResponce) => {
    await attachDeveloperToProjectMutation({
      variables: {
        input: {
          developerId: Number(newDeveloperId),
          projectId: Number(id),
        },
      },
      refetchQueries: [GetProjectCustomDocument],
    });
  };

  const handleChange =
    ({ newDeveloperId, defaultRank, defaultRate }: EditResponce) =>
    () => {
      if (params.row.name) {
        editDeveloper({ newDeveloperId, defaultRank, defaultRate });
      } else {
        attachDeveloper({ newDeveloperId });
      }
    };

  const setNameInRow = (event: SelectChangeEvent) => {
    setName(event.target.value);
  };

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id="demo-simple-select-label">Name</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={params.row.name.length ? name : ""}
        label="Name"
        onChange={setNameInRow}
        MenuProps={{
          style: {
            maxHeight: 250,
          },
        }}>
        <MenuItem
          value={params.row.name}
          onClick={handleChange({
            newDeveloperId: params.id as string,
            defaultRank: params.row.defaultRank,
            defaultRate: params.row.defaultRate,
          })}>
          {params.row.name}
        </MenuItem>
        {allDevelopers.map((developer: Partial<DeveloperEntity>) => (
          <MenuItem
            key={developer.id}
            value={developer.name}
            onClick={handleChange({
              newDeveloperId: developer.id as string,
              defaultRank: developer.defaultRank as string,
              defaultRate: developer.defaultRate as number,
            })}>
            {developer.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NameCell;
