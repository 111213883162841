import React, { useMemo, useState, useEffect } from "react";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { limitItemsCount } from "constants/index";
import { FileData } from "screens/ProjectDetailsScreen/types";
import { format } from "date-fns";
import ActionsTable from "components/ProjectFilesTable/ActionsTable";
import styles from "./styles";

interface ProjectFilesTableProps {
  rows: FileData[];
  count: number;
}

const formatRows = (rows: Array<FileData>) => {
  return rows.map((row: FileData) => {
    const createdTime = new Date(Number(row.createdAt));
    return {
      ...row,
      createdAt: format(createdTime, "dd-MM-yyyy 'at' h:mm a "),
    };
  });
};

const ProjectFilesTable: React.FC<ProjectFilesTableProps> = ({
  rows,
  count,
}) => {
  const [rowState, setRowState] = useState<Array<FileData>>(rows);

  const columns = useMemo(
    () => [
      {
        field: "createdAt",
        headerName: "createdAt",
        width: 220,
        minWidth: 100,
        sortable: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params: GridCellParams) => (
          <ActionsTable {...{ params }} />
        ),
        width: 20,
        minWidth: 100,
        filterable: false,
      },
    ],
    [rows]
  );

  useEffect(() => {
    setRowState(formatRows(rows));
  }, [rows]);

  return (
    <DataGrid
      rows={rowState}
      columns={columns}
      pageSize={limitItemsCount}
      rowCount={count}
      checkboxSelection={false}
      autoHeight
      autoPageSize
      disableSelectionOnClick
      disableColumnMenu
      sx={styles}
    />
  );
};

export default ProjectFilesTable;
