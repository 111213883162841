import { Task } from "../types";
import styles from "../index.module.css";
import clsx from "clsx";

export const createTaskNameColumn = () => ({
  name: "name",
  label: "Task name",
  tree: true,
  width: "200",
  align: "center",
  template: function (task: Task) {
    return `<div class="${clsx(styles.taskName, "taskName")}">
                <i class="${clsx(
                  "material-icons",
                  styles.muiIcon,
                  {
                    project: styles.sprintIcon,
                    story: styles.storyIcon,
                    task: styles.taskIcon,
                  }[task.type]
                )}">
                    task
                </i>
                ${task.name}
            </div>`;
  },
});
