export const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "20px",
  },
};
