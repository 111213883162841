import { Task } from "../components/Gantt/types";

export const getWeekOfMonthNumber = (date: Date) => {
  const adjustedDate = date.getDate() + date.getDay();
  const prefixes = ["0", "1", "2", "3", "4", "5"];
  return parseInt(prefixes[0 | (adjustedDate / 7)]) + 1;
};

export const getWeekFormatFunction = function (date: Date) {
  return "Week " + getWeekOfMonthNumber(date);
};

export const getMinDateAmongTasks = function (tasks: Task[]) {
  const now = new Date().getTime();
  const startDate = tasks.reduce((acc, next) => {
    if (!next.start_date) return acc;
    if (Number(next.start_date) < Number(acc)) {
      acc = Number(next.start_date);
    }
    return acc;
  }, now);

  return new Date(startDate - 86400000);
};

export const getMaxDateAmongTasks = function (tasks: Task[]) {
  const endDate = tasks.reduce((acc, next) => {
    if (!next.end_date) return acc;
    if (Number(next.end_date) > Number(acc)) {
      acc = Number(next.end_date);
    }
    return acc;
  }, 0);

  return new Date(endDate + 86400000);
};
