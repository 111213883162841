export function getSegmentUrl(str: string) {
  switch (str) {
    case "uk":
      return "ua";
    case "en":
      return "gb";
    default:
      return str;
  }
}
