export const styles = {
  input: {
    margin: "12px 0px 12px 0px",
  },
  buttonBox: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-around",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
};
