import { Outlet } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "graphql/client/client";
import { ThemeProvider } from "@mui/material";
import { theme } from "theme/theme";
import { ModalContextProvider } from "context/ModalContext";
import { AlertContextProvider } from "context/AlertContext";
import ModalLayout from "components/ModalController/ModalLayout";
import AlertLayout from "components/Alert/AlertLayout";
import { DrawerContextProvider } from "context/DrawerContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <DrawerContextProvider>
          <AlertContextProvider>
            <ModalContextProvider>
              <AlertLayout>
                <ModalLayout>
                  <Outlet />
                </ModalLayout>
              </AlertLayout>
            </ModalContextProvider>
          </AlertContextProvider>
        </DrawerContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
