import { UserStatusEnum } from "../graphql/generated";

export const getChipVariant = (status: UserStatusEnum) => {
  switch (status) {
    case UserStatusEnum.Active:
      return "active";
    case UserStatusEnum.Inactive:
      return "inactive";
    case UserStatusEnum.Banned:
      return "banned";
    default:
      return "active";
  }
};
