import App from "App";
import { createBrowserRouter } from "react-router-dom";
import OnlyAdmin from "roots/OnlyAdmin";
import PrivateRoot from "roots/PrivateRoot";
import PublicRoot from "roots/PublicRoot";
import Screen404 from "screens/404Screen";
import LogInScreen from "screens/AuthScreens/LogInScreen";
import PasswordResetConfirmScreen from "screens/AuthScreens/PasswordResetConfirmScreen";
import PasswordResetScreen from "screens/AuthScreens/PasswordResetScreen";
import DevelopersScreen from "screens/DevelopersScreen";
import ProjectDetailsScreen from "screens/ProjectDetailsScreen";
import ProjectsScreen from "screens/ProjectsScreen";
import SettingsScreen from "screens/SettingsScreen";
import UsersScreen from "screens/UsersScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <PublicRoot>
            <LogInScreen />
          </PublicRoot>
        ),
      },
      {
        path: "password-reset",
        element: (
          <PublicRoot>
            <PasswordResetScreen />
          </PublicRoot>
        ),
      },
      {
        path: "password-reset-confirm",
        element: (
          <PublicRoot>
            <PasswordResetConfirmScreen />
          </PublicRoot>
        ),
      },
      {
        path: "/",
        element: (
          <PrivateRoot>
            <ProjectsScreen />
          </PrivateRoot>
        ),
      },
      {
        path: "developers",
        element: (
          <PrivateRoot>
            <OnlyAdmin>
              <DevelopersScreen />
            </OnlyAdmin>
          </PrivateRoot>
        ),
      },
      {
        path: "settings",
        element: (
          <PrivateRoot>
            <OnlyAdmin>
              <SettingsScreen />
            </OnlyAdmin>
          </PrivateRoot>
        ),
      },
      {
        path: "users",
        element: (
          <PrivateRoot>
            <OnlyAdmin>
              <UsersScreen />
            </OnlyAdmin>
          </PrivateRoot>
        ),
      },
      {
        path: "/project/:id",
        element: (
          <PrivateRoot>
            <ProjectDetailsScreen />
          </PrivateRoot>
        ),
      },
      {
        path: "*",
        element: <Screen404 />,
      },
    ],
  },
]);

export default router;
