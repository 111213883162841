import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "components/Layout/Layout";
import {
  GetProjectCustomQuery,
  ProjectEntity,
  useGetProjectCustomQuery,
} from "graphql/generated";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import TasksTab from "screens/ProjectDetailsScreen/TasksTab";
import SettingsTab from "screens/ProjectDetailsScreen/SettingsTab";
import DevelopersTab from "screens/ProjectDetailsScreen/DevelopersTab";
import FilesTab from "screens/ProjectDetailsScreen/FilesTab";

import {
  Developer,
  FormData,
  FormDataProjectDevelopers,
  TabPanelProps,
} from "screens/ProjectDetailsScreen/types";
import { useUserAccess } from "../../hooks";

const TabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ProjectDetailsScreen = () => {
  const [value, setValue] = useState<number>(0);
  const [isChart, setIsChart] = useState<boolean>(false);
  const { id } = useParams();
  const { isEstimator } = useUserAccess();

  const tabsData = [
    { label: "Задачи" },
    { label: "Настройки" },
    ...(!isEstimator ? [{ label: "Разработчики" }] : []),
    ...(!isEstimator ? [{ label: "Файлы" }] : []),
  ];

  const [projectDevelopers, setProjectDevelopers] = useState<
    Array<Developer> | []
  >([]);

  const onCompleted = (data: GetProjectCustomQuery) => {
    setIsChart(data.getProject.isWithChart);

    if (data) {
      setProjectDevelopers(() =>
        data?.getProject.projectDevelopers.map(
          (item: FormDataProjectDevelopers) => {
            return {
              defaultRank: item.rank,
              defaultRate: item.rate,
              id: item.developerId,
              name: item.developer.name,
            };
          }
        )
      );
    }
  };

  const { data, loading, refetch } = useGetProjectCustomQuery({
    variables: {
      projectId: Number(id),
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: onCompleted,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout namePage={data?.getProject?.name}>
      <Tabs value={value} onChange={handleChange}>
        {tabsData.map((tabData, i) => (
          <Tab label={tabData.label} key={tabData.label} {...a11yProps(i)} />
        ))}
      </Tabs>
      <TabPanel value={value} index={0}>
        <TasksTab
          isChart={isChart}
          sprints={(!loading && data?.getProject.sprints) || ([] as any)}
          developers={projectDevelopers}
          id={id}
          projectId={data?.getProject.id}
          refetchProjectData={refetch}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SettingsTab
          setIsChart={setIsChart}
          isChart={isChart}
          project={data?.getProject as ProjectEntity}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DevelopersTab id={Number(id)} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FilesTab
          files={data?.getProject?.files || []}
          count={data?.getProject?.files?.length || 0}
        />
      </TabPanel>
    </Layout>
  );
};

export default ProjectDetailsScreen;
