import { ChangeEvent } from "react";

export const handleTaskDblClick = () => false;
export const handleToggleTree = (e: ChangeEvent<HTMLElement>, id: string) => {
  // if element includes "gantt_close" => then it's in open state
  if (e.target.classList.contains("gantt_close")) {
    gantt.close(id);
  } else {
    gantt.open(id);
  }
};
