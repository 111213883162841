import { gql } from "graphql-tag";

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
      role
      name
      email
      avatar
      status
      createdAt
      updatedAt
    }
  }
`;
