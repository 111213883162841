import React from "react";
import ProjectsScreen from "screens/ProjectsScreen";

interface PublicRootProps {
  children: React.ReactElement;
}

const PublicRoot: React.FC<PublicRootProps> = ({ children }) => {
  const token = localStorage.getItem("token");
  return token ? <ProjectsScreen /> : children;
};

export default PublicRoot;
