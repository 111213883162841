import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

export const Dropzone = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  color: #707070;
  border: 1px dashed #bdbdbd;
  font-size: 14px;
  cursor: pointer;

  input {
    display: none;
  }
`;

export const Input = styled.input`
  width: 80%;
  height: 40px;
  border: 1px solid #bdbdbd;
  padding: 0 20px;
  margin: 15px 0;
`;

export const ErrorMessage = styled.span`
  display: block;
  color: red;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 80%;
  height: 40px;
  background: #19a0d8;
  color: #fff;
  font-weight: bold;
  border: 0;
`;

export const SelectedFileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  juctify-content: space-around;
  align-items: center;
  height: 80px;
`;

export const SelectedFile = styled.span`
  font-size: 1rem;
`;

export const ClearFile = styled(CloseIcon)`
  border: 0;
  background: transparent;
  color: red;
  width: 1.5rem;
  cursor: pointer;
`;

export const img = {
  position: "absolute",
  right: 0,
  width: 72,
  height: 72,
};
