export const adjustEndTime = (endTime: Date) => {
  if (!endTime) return new Date();
  // Проверяем, больше или равно ли время `endTime` 16:00
  if (endTime.getHours() >= 16 && endTime.getMinutes() === 0) {
    // Проверяем, является ли следующий день выходным (субботой или воскресеньем)
    const nextDay = new Date(endTime);
    nextDay.setDate(endTime.getDate() + 1);

    if (nextDay.getDay() === 0) {
      // Если следующий день воскресенье, переносим на понедельник
      nextDay.setDate(nextDay.getDate() + 1);
    } else if (nextDay.getDay() === 6) {
      // Если следующий день суббота, переносим на воскресенье
      nextDay.setDate(nextDay.getDate() + 2);
    }
    // Устанавливаем на 8:00
    nextDay.setHours(8, 0, 0);
    return nextDay;
  }

  return endTime;
};
