import React, { Dispatch, SetStateAction, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import {
  useCreateStoryMutation,
  useEditStoryMutation,
} from "graphql/generated";
import { styles } from "components/ModalController/ModalCreateStoryTask/StoryModal/styles";
import { useModalContext } from "context/ModalContext";
import { storySchemaCreate } from "validations/StoryCreateValidations";
import { useAlertContext } from "context/AlertContext";
import { alertTypes } from "constants/constants";

interface FormData {
  name: string;
}

interface StoryModal {
  sharedName: string;
  setSharedName: Dispatch<SetStateAction<string>>;
}

const StoryModal: React.FC<StoryModal> = ({ sharedName, setSharedName }) => {
  const { modalParameters, handleChangeModalParameters } = useModalContext();
  const [createStoryMutation] = useCreateStoryMutation();
  const [editStoryMutation] = useEditStoryMutation();
  const { handleChangeAlertParameters } = useAlertContext();

  const { sprintId, isChangeName } = modalParameters.params;
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(storySchemaCreate),
    defaultValues: {
      name: isChangeName
        ? modalParameters?.params?.defaultValues?.name
        : sharedName,
    },
  });

  const nameValue = watch("name");

  useEffect(() => {
    setSharedName(nameValue);
  }, [nameValue, setSharedName]);

  const handleClose = () => {
    handleChangeModalParameters({
      isOpen: false,
    });
  };

  const onSubmit = async (data: FormData) => {
    if (isChangeName) {
      const response = await editStoryMutation({
        variables: {
          input: {
            name: data.name,
          },
          storyId: Number(modalParameters.params.storyId),
        },
      });

      if (response?.data?.editStory?.name) {
        handleChangeAlertParameters({
          isOpen: true,
          type: alertTypes.success,
          message: "Story успешно отредактировано",
        });
      }
    } else {
      await createStoryMutation({
        variables: {
          input: {
            name: data.name,
            sprintId: Number(sprintId),
          },
        },
      });
    }

    await modalParameters.params.refetchProjectData?.();

    handleClose();
    reset();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={styles.form}>
      <TextField
        {...register("name")}
        sx={styles.input}
        label="Story Name"
        variant="outlined"
        helperText={errors?.name && errors?.name?.message}
        error={!!errors?.name}
        autoFocus
      />
      <Box sx={styles.buttonBox}>
        <Button variant="contained" type="submit">
          {isChangeName ? "Change" : "Create"}
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default StoryModal;
