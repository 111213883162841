export const styles = {
  alert: (position: "bottom" | "top") => {
    const commonStyles = {
      position: "fixed",
      right: 0,
      left: 0,
      width: "40%",
      marginLeft: "auto",
      marginRight: "auto",
      // This styles is to forbid height jumping on first alert render
      maxHeight: "50px",
    };
    if (position === "bottom") return { ...commonStyles, bottom: "30px" };
    if (position === "top") return { ...commonStyles, top: "30px" };
  },
};
