import React from "react";
import LogInScreen from "screens/AuthScreens/LogInScreen";

interface PrivateRootProps {
  children: React.ReactElement;
}

const PrivateRoot: React.FC<PrivateRootProps> = ({ children }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <LogInScreen />;
  } else {
    return children;
  }
};

export default PrivateRoot;
