import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sprintSchemaCreate } from "validations/SprintCreate";
import TextField from "@mui/material/TextField";
import {
  GetProjectCustomDocument,
  useCreateSprintMutation,
  useEditSprintMutation,
} from "graphql/generated";
import { styles } from "components/ModalController/ModalCreateSprint/styles";
import { useModalContext } from "context/ModalContext";
import { alertTypes } from "constants/constants";
import { useAlertContext } from "context/AlertContext";

interface ModalCreateSprintProps {
  open: boolean;
}

interface FormData {
  name: string;
}

const ModalCreateSprint: React.FC<ModalCreateSprintProps> = ({ open }) => {
  const { modalParameters, handleChangeModalParameters } = useModalContext();
  const { handleChangeAlertParameters } = useAlertContext();

  const [createSprintMutation] = useCreateSprintMutation({
    refetchQueries: [GetProjectCustomDocument],
  });
  const [editSprintMutation] = useEditSprintMutation({
    onCompleted: () => {
      handleChangeAlertParameters({
        isOpen: true,
        type: alertTypes.success,
        message: "Sprint успешно отредактировано",
      });
    },
  });

  const isChangeName = modalParameters?.params?.isChangeName;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(sprintSchemaCreate),
    defaultValues: {
      name: isChangeName ? modalParameters?.params?.defaultValues?.name : "",
    },
  });

  const handleClose = () => {
    handleChangeModalParameters({
      isOpen: false,
    });
  };

  const onSubmit = async (data: FormData) => {
    if (isChangeName) {
      await editSprintMutation({
        variables: {
          input: {
            name: data.name,
          },
          sprintId: Number(modalParameters.params.id),
        },
      });
    } else {
      await createSprintMutation({
        variables: {
          projectId: Number(modalParameters.params.id),
          input: {
            name: data.name,
          },
        },
      });
    }

    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styles.container}>
        <Typography
          align="center"
          id="modal-modal-title"
          variant="h6"
          component="h2">
          {isChangeName ? "Change " : "Create "}
          Sprint
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={styles.form}>
          <TextField
            {...register("name")}
            sx={styles.input}
            label="Name Sprint"
            variant="outlined"
            helperText={errors?.name && errors?.name?.message}
            error={!!errors?.name}
            autoFocus
          />
          <Box sx={styles.buttonBox}>
            <Button variant="contained" type="submit">
              {isChangeName ? "Change" : "Create"}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalCreateSprint;
