export const styles = {
  container: {
    position: "relative",
    margin: "30px 60px 60px",
  },
  buttonCreate: {
    marginBottom: "22px",
  },
  buttonIcon: {
    marginRight: "4px",
  },
};
