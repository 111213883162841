import * as yup from "yup";

type SettingsTabSchemaProps = {
  isAdmin: boolean;
  isManager: boolean;
  isEstimator: boolean;
};
export const settingsTabSchema = ({
  isAdmin,
  isManager,
  isEstimator,
}: SettingsTabSchemaProps) =>
  yup.object().shape({
    ...(!isEstimator && {
      name: yup.string().required(),
      status: yup.string().required(),
      isWithChart: yup.boolean(),
    }),
    recommendations: yup.string().nullable(),
    comments: yup.string().nullable(),
    aboutUs: yup.string().nullable(),
    ...((isManager || isAdmin) && {
      projectEstimators: yup
        .array()
        .min(1, "Select Estimators is a required field")
        .required(),
    }),
    ...(isAdmin && {
      userId: yup.string().required(),
    }),
  });
