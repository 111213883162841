import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styles } from "components/ModalController/ModalCreateStoryTask/styles";
import { useModalContext } from "context/ModalContext";
import CustomSwitch from "components/ModalController/ModalCreateStoryTask/components/CustomSwitch";
import StoryModal from "components/ModalController/ModalCreateStoryTask/StoryModal";
import TaskModal from "components/ModalController/ModalCreateStoryTask/TaskModal";
import { projectFieldTypes } from "constants/constants";

interface ModalCreateHistoryTaskProps {
  open: boolean;
}

const ModalCreateHistoryTask: React.FC<ModalCreateHistoryTaskProps> = ({
  open,
}) => {
  const { modalParameters, handleChangeModalParameters } = useModalContext();
  /* Shared Name will be used as a default value to both forms in TaskModal and StoryModal */
  const [sharedName, setSharedName] = useState("");

  const { taskType, defaultValues } = modalParameters.params;
  const disabled = taskType === "story";
  const [isSwitch, setIsSwitch] = useState<boolean>(disabled);
  const isChangeName = modalParameters?.params?.isChangeName;

  const handleClose = () => {
    handleChangeModalParameters({
      isOpen: false,
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styles.container}>
        <Box sx={styles.contentBox}>
          {taskType === projectFieldTypes.project && !isChangeName ? (
            <Box sx={styles.switch}>
              <CustomSwitch
                setIsSwitch={setIsSwitch}
                isSwitch={isSwitch}
                disabled={disabled}
              />
            </Box>
          ) : null}
          {/* TODO: need to refactor this piece of sh*t, because it cuts the re-usability */}
          {isSwitch ? (
            <TaskModal
              sharedName={sharedName}
              setSharedName={setSharedName}
              defaultValues={defaultValues}
            />
          ) : (
            <StoryModal sharedName={sharedName} setSharedName={setSharedName} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalCreateHistoryTask;
