import React, { useContext, useState } from "react";

type paramsType = {
  cancelAction: () => void;
  confirmAction: () => void;
  value?: string;
  type?: string;
  isOpen: boolean;
  params: any;
};

export const ModalContext = React.createContext<any>({});

export function useModalContext() {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error("useModalContext must be used within a AuthProvider");
  }

  return modalContext;
}

export const ModalContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [modalParameters, setModalParameters] = useState({
    cancelAction: () => {},
    confirmAction: () => {},
    value: "",
    type: "",
    isOpen: false,
    params: {},
  });

  const handleChangeModalParameters = (params: paramsType) => {
    setModalParameters((prevModalParameters) => ({
      ...prevModalParameters,
      ...params,
    }));
  };

  const handleClose = () => {
    setModalParameters((prevState) => {
      return { ...prevState, isOpen: false };
    });
  };

  return (
    <ModalContext.Provider
      value={{
        isAuth: false,
        handleChangeModalParameters: handleChangeModalParameters,
        modalParameters: modalParameters,
        handleClose,
      }}>
      {children}
    </ModalContext.Provider>
  );
};
