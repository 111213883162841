import { UserEntity } from "../graphql/generated";

export const getFormatDataForSelectAndAutocomplete = (users?: UserEntity[]) => {
  if (!users || !users.length) return [];
  return users.map((user) => ({
    id: user.id,
    label: user.name,
    value: user.id,
    status: user.status,
  }));
};
