export const styles = {
  container: {
    width: "43vw",
    padding: "8px",
    position: "relative",
  },
  input: {
    flex: "1",
  },
  checkbox: {
    margin: "6px 0px 12px 0px",
  },
  button: {
    width: "100%",
  },
  editorWrapper: (error?: boolean) => {
    return {
      padding: error ? "2px" : "",
      border: error ? "1px solid #f46e6f" : "",
      height: "170px",
      borderRadius: "5px",
      marginBottom: "150px",
    };
  },
};
