import { gql } from "graphql-tag";

export const GET_PROJECT = gql(`
  query GetProjectCustom($projectId: Int!) {
    getProject(projectId: $projectId) {
      id
      name
      status
      isWithChart
      recommendations
      comments
      aboutUs
      language
      files {
        id
        name
        createdAt
      }
      projectDevelopers {
        projectId
        developerId
        rate
        rank
        developer {
          id
          name
          defaultRank
          defaultRate
        }
      }
      sprints {
        id
        name
        sortOrder
        totalHours
        name
        startedAt
        storiesAndTasks {
          ... on StoryEntity {
            id
            name
            sprintId
            sortOrder
            startedAtStory: startedAt
            tasks {
              id
              name
              sprintId
              storyId
              developerId
              startedAt
              sortOrder
              createdAt
              updatedAt
              developer {
                defaultRate
                defaultRank
                id
                name
                avatar
              }
              duration
            }
            totalHours
          }
          ... on TaskEntity {
            id
            name
            sprintId
            storyId
            developerId
            startedAt
            sortOrder
            createdAt
            updatedAt
            developer {
              defaultRate
              defaultRank
              id
              name
              avatar
            }
            duration
          }
        }
      }
      user {
        id
        name
        status
      }
      projectEstimators {
        id
        name
        status
      }
    }
  }
`);
