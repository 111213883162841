import { projectFieldTypes } from "constants/constants";
import { Task } from "../types";
import styles from "../index.module.css";
import clsx from "clsx";

export const createActionButtonsColumn = () => {
  return {
    name: "buttons",
    label: "Actions",
    width: 90,
    align: "center",
    template: (task: Task) => {
      return task.type !== projectFieldTypes.task
        ? `<i class="medium material-icons ${clsx(
            styles.actionButtons,
            styles.button
          )}" 
              data-type="${task.type}" 
              data-storyId="${task.storyId}" 
              data-sprintId="${task.sprintId}" 
              data-action="add"
            >
              add
            </i>
            <i class="medium material-icons ${clsx(
              styles.actionButtons,
              styles.button
            )}" 
              data-type="${task.type}" 
              data-storyId="${task.storyId}" 
              data-sprintId="${task.sprintId}" 
              data-action="edit"
            >
              edit
            </i>
            <i class="medium material-icons ${clsx(
              styles.actionButtons,
              styles.button,
              styles.deleteIcon
            )}" 
              data-type="${task.type}" 
              data-id="${task.ID}" 
              data-action="delete"
            >
              delete
            </i>`
        : `
            <i class="medium material-icons ${clsx(
              styles.actionButtons,
              styles.button
            )}" 
              data-type="${task.type}" 
              data-storyId="${task.storyId}" 
              data-sprintId="${task.sprintId}" 
              data-action="edit"
            >
              edit
            </i>
            <i class="medium material-icons ${clsx(
              styles.actionButtons,
              styles.button,
              styles.deleteIcon
            )}" 
              data-id="${task.ID}" 
              data-type="${task.type}" 
              data-action="delete"
            >
              delete
            </i>`;
    },
  };
};
