const dataGridStyles = {
  "&.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-cell .MuiDataGrid-cellContent":
    {
      display: "flex",
      alignItems: "center",
    },
};

export const deleteIconStyles = {
  color: "#b60000",
};

export const iconButtonSize = {
  width: "40px",
  height: "40px",
};

export default dataGridStyles;
