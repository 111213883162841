import { GanttTemplates } from "dhtmlx-gantt";
import styles from "./index.module.css";
import clsx from "clsx";
export const taskClassCallback: GanttTemplates["task_class"] = (
  start,
  end,
  task
) => {
  if (task.type == gantt.config.types.story) {
    return "story_task";
  }
  return "";
};

export const taskTextCallback: GanttTemplates["task_text"] = (
  start,
  end,
  task
) => {
  return task.text;
};

export const gridOpenCallback: GanttTemplates["grid_folder"] = function (item) {
  return `<div data-action='toggle_tree' class='${clsx(
    "gantt_tree_icon",
    styles.ganttTreeIcon,
    item.$open ? "gantt_close" : "gantt_open"
  )}'></div>`;
};
export const gridFolderCallback: GanttTemplates["grid_folder"] = () => "";
export const gridFileCallback: GanttTemplates["grid_file"] = function () {
  return `<div class='gantt_tree_icon ${styles.ganttTreeIcon}'></div>`;
};

export const gridBlankCallback: GanttTemplates["grid_blank"] = function () {
  return "";
};

export const timelineCellClassCallback: GanttTemplates["timeline_cell_class"] =
  function (item, date) {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return "gantt_cell_weekend";
    }
    return "";
  };
