import { gql } from "graphql-tag";

export const GET_USERS = gql`
  query GetUsers($input: GetUsersInput!) {
    getUsers(input: $input) {
      users {
        id
        email
        name
        avatar
        status
        role
        createdAt
        updatedAt
        numberOfCreatedProjects
      }
      count
    }
  }
`;
