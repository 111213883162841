import { client } from "graphql/client/client";
import { useGetUserQuery } from "graphql/generated";
import { GET_USER } from "graphql/queries/getUser.gql";
import { FC } from "react";
import { Navigate } from "react-router";

interface OnlyAdminProps {
  children: React.ReactElement;
}

const OnlyAdmin: FC<OnlyAdminProps> = ({ children }) => {
  const cache = client.readQuery({
    query: GET_USER,
  });

  const { data } = useGetUserQuery({ skip: Boolean(cache) });

  const isAdmin =
    cache?.getUser?.role === "ADMIN" || data?.getUser?.role === "ADMIN";

  if ((data || cache) && !isAdmin) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
};

export default OnlyAdmin;
