import { gql } from "graphql-tag";

export const GET_PROJECTS = gql`
  query getProjects($offset: Int, $limit: Int) {
    getProjects(offset: $offset, limit: $limit) {
      projects {
        id
        name
        createdAt
        updatedAt
        status
        totalHours
        isWithChart
        recommendations
        comments
        aboutUs
        projectDevelopers {
          developer {
            id
            name
            defaultRate
            defaultRank
          }
        }
      }
      count
    }
  }
`;
