export const styles = {
  container: {
    maxHeight: "100vh",
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
  },
  modalControllerBox: {
    position: "relative",
    margin: "auto",
    width: "fit-content",
    height: "fit-content",
    padding: "30px",
    outline: "0 !important",
  },
};
