import React, { useContext, useEffect, useState } from "react";

type DrawerContextType = {
  open: boolean;
  setOpen(value: boolean): void;
};

type Props = {
  children: React.ReactNode;
};

export const DrawerContext = React.createContext<DrawerContextType>({
  open: false,
  setOpen(value: boolean) {
    return value;
  },
});

export function useDrawerContext() {
  return useContext(DrawerContext);
}

export const DrawerContextProvider: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const result = localStorage.getItem("open");
    if (result) {
      setOpen(!!result);
    }
  }, []);

  return (
    <DrawerContext.Provider
      value={{
        open,
        setOpen,
      }}>
      {children}
    </DrawerContext.Provider>
  );
};
