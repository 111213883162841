import * as GENERATED from "../generated/index";
import { GET_PROJECT } from "../queries/getProject.gql";
import { useAlertContext } from "../../context/AlertContext";
import { alertTypes } from "../../constants";

type useEditTaskType = {
  projectId?: string | number;
  withNotification: boolean;
};
export const useEditTask = ({
  projectId,
  withNotification,
}: useEditTaskType) => {
  const { handleChangeAlertParameters } = useAlertContext();

  return GENERATED.useEditTaskMutation({
    ...(typeof projectId !== "undefined" &&
      {
        // refetchQueries: () => [
        //   {
        //     query: GET_PROJECT,
        //     variables: {
        //       projectId: +projectId,
        //     },
        //   },
        // ],
      }),
    // fetchPolicy: "network-only",
    onCompleted: () => {
      handleChangeAlertParameters({
        isOpen: withNotification,
        type: alertTypes.success,
        message: "Task успешно отредактировано",
      });
    },
    onError: () => {
      handleChangeAlertParameters({
        isOpen: true,
        type: alertTypes.error,
        message: "Произошла ошибка",
      });
    },
  });
};
