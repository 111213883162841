export const ROUTES = [
  {
    name: "Проекты",
    path: "/",
  },
  {
    name: "Пользователи",
    path: "/users",
  },
  {
    name: "Разработчики",
    path: "/developers",
  },
  {
    name: "Настройки",
    path: "/settings",
  },
];
