export const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  input: {
    margin: "12px 0px 12px 0px",
  },

  checkbox: {
    margin: "6px 0px 12px 0px",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "22px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
};
