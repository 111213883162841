import { NavLink, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import ListItemMenu from "../ListItemMenu/ListItemMenu";

interface CustomLinkProps {
  to: string;
  open: boolean;
  routeName: string;
}

export const CustomLink = ({ to, open, routeName }: CustomLinkProps) => {
  const location = useLocation();
  const match =
    location.pathname === to ||
    (location.pathname.split("/")[1] === "project" && to === "/");

  return (
    <NavLink to={to} className={match ? styles.active : styles.inactive}>
      <ListItemMenu open={open} title={routeName} />
    </NavLink>
  );
};
