import React from "react";
import IconButton from "@mui/material/IconButton";
import { Delete } from "@mui/icons-material";
import { GridCellParams } from "@mui/x-data-grid";
import { useModalContext } from "context/ModalContext";
import { alertTypes, TYPE_MODAL } from "constants/index";
import {
  GetProjectCustomDocument,
  useDetachDeveloperFromProjectMutation,
} from "graphql/generated";
import { useParams } from "react-router-dom";
import { Developer } from "screens/ProjectDetailsScreen/types";
import { useAlertContext } from "context/AlertContext";
import { deleteIconStyles } from "../ProjectsTable/styles";

interface TableActionsProps {
  params: GridCellParams;
  setDevelopers: (value: any) => void;
  developers: Developer[];
}

const TableActions: React.FC<TableActionsProps> = ({
  params,
  setDevelopers,
  developers,
}) => {
  const { handleChangeModalParameters } = useModalContext();
  const { handleChangeAlertParameters } = useAlertContext();

  const [detachDeveloperFromProjectMutation] =
    useDetachDeveloperFromProjectMutation();
  const { id } = useParams();

  const detachDeveloper = async () => {
    if (params.row.name) {
      await detachDeveloperFromProjectMutation({
        variables: {
          projectId: Number(id),
          developerId: Number(params.id),
        },
        onError: ({ graphQLErrors }) => {
          closeModalDelete();

          const error = graphQLErrors[0] as unknown as Error[];

          handleChangeAlertParameters({
            isOpen: true,
            message: error[0].message,
            type: alertTypes.error,
          });
        },
        refetchQueries: [GetProjectCustomDocument],
      });
    } else {
      const newArr = [...developers];
      newArr.pop();
      setDevelopers(newArr);
    }

    closeModalDelete();
  };

  const closeModalDelete = () => [
    handleChangeModalParameters({
      isOpen: false,
      type: TYPE_MODAL.DELETE,
    }),
  ];

  const handleClick = () => {
    handleChangeModalParameters({
      isOpen: true,
      type: TYPE_MODAL.DELETE,
      cancelAction: closeModalDelete,
      confirmAction: detachDeveloper,
      params: {
        name: "developer in this project",
      },
    });
  };

  return (
    <IconButton onClick={handleClick} sx={{ ...deleteIconStyles }}>
      <Delete />
    </IconButton>
  );
};

export default TableActions;
