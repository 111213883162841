import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import {
  GetDevelopersListQuery,
  useAttachDeveloperToProjectMutation,
  useCreateTaskMutation,
  useGetDevelopersListQuery,
} from "graphql/generated";
import { styles } from "components/ModalController/ModalCreateStoryTask/StoryModal/styles";
import { useModalContext } from "context/ModalContext";
import {
  hasStartedAtFieldTaskSchemaCreate,
  hasNotStartedAtFieldTaskSchemaCreate,
} from "validations/TaskCreateValidations";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { useEditTask } from "graphql/hooks";
import ListSubheader from "@mui/material/ListSubheader";
import { Developer, TaskModalProps, FormData } from "./types";
import { useGetInOutDevelopers } from "./hooks";
import { removeArchivedDevelopers } from "../../../../utils";
import Typography from "@mui/material/Typography";
import { useUserAccess } from "../../../../hooks";

const TaskModal: React.FC<TaskModalProps> = ({
  sharedName,
  setSharedName,
  defaultValues,
}) => {
  // Hooks
  const [activeDevelopers, setActiveDevelopers] = useState<Developer[]>([]);
  const { modalParameters, handleChangeModalParameters } = useModalContext();
  const { sprintId, isChart, storyId, taskId, projectId, developers } =
    modalParameters.params;

  const { isEstimator } = useUserAccess();

  const { data, fetchMore } = useGetDevelopersListQuery({
    variables: {
      offset: 0,
    },
    onCompleted: (res) => {
      setActiveDevelopers(
        removeArchivedDevelopers(res.getDevelopersList.developers)
      );
    },
  });

  const [inDevelopers, outDevelopers] = useGetInOutDevelopers(
    developers as Developer[],
    activeDevelopers as Developer[]
  );

  const [createTaskMutation] = useCreateTaskMutation();
  const [attachDeveloperToProjectMutation] =
    useAttachDeveloperToProjectMutation();
  const [editTask] = useEditTask({ withNotification: true });

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(
      isChart
        ? hasStartedAtFieldTaskSchemaCreate
        : hasNotStartedAtFieldTaskSchemaCreate
    ),
    defaultValues: {
      name: sharedName,
      ...defaultValues,
    },
  });

  const nameValue = watch("name");

  useEffect(() => {
    setSharedName(nameValue);
  }, [nameValue, setSharedName]);

  // Methods

  const updateGetDevelopersListQuery = (
    previousResult: GetDevelopersListQuery,
    { fetchMoreResult }: { fetchMoreResult: GetDevelopersListQuery }
  ): GetDevelopersListQuery => {
    return {
      getDevelopersList: {
        ...fetchMoreResult.getDevelopersList,
        developers: [
          ...previousResult.getDevelopersList.developers,
          ...fetchMoreResult.getDevelopersList.developers,
        ],
      },
    };
  };

  const loadMoreItems: React.UIEventHandler<HTMLDivElement> = (e) => {
    const eTarget = e.target as HTMLElement;
    const scrolledToBottom =
      eTarget.scrollHeight - 10 < eTarget.clientHeight + eTarget.scrollTop;

    const doFetchMore =
      data?.getDevelopersList.developers.length !==
      data?.getDevelopersList.count;

    if (scrolledToBottom && doFetchMore) {
      fetchMore({
        variables: {
          offset: data?.getDevelopersList.developers.length,
        },
        updateQuery: updateGetDevelopersListQuery,
      });
    }
  };

  const handleClose = () => {
    handleChangeModalParameters({
      isOpen: false,
    });
  };

  const onSubmit = async (data: FormData) => {
    const isAlreadyExist = developers.some(
      (dev: Developer) => String(dev.id) === String(data.developerId)
    );
    // if we don't have Developer else skip this flow
    if (!isAlreadyExist) {
      // add developer to project
      await attachDeveloperToProjectMutation({
        variables: {
          input: {
            developerId: Number(data.developerId),
            projectId: Number(projectId),
          },
        },
      });
    }

    if (taskId) {
      await editTask({
        variables: {
          taskId: taskId as number,
          input: {
            ...data,
            startedAt: isChart ? data.startedAt : new Date(),
            developerId: Number(data.developerId),
          },
        },
      });
    } else {
      await createTaskMutation({
        variables: {
          input: {
            ...data,
            startedAt: isChart ? data.startedAt : new Date(),
            developerId: Number(data.developerId),
            sprintId: Number(sprintId),
            storyId: Number(storyId),
          },
        },
      });
    }

    await modalParameters.params.refetchProjectData?.();

    handleClose();
    reset();
  };

  const handleDateChange = (e: Date | null) => {
    setValue("startedAt", e);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={styles.form}>
      <TextField
        {...register("name")}
        sx={styles.input}
        label="Task Name"
        variant="outlined"
        helperText={errors?.name && errors?.name?.message}
        error={!!errors?.name}
        autoFocus
      />

      {isChart && (
        <Controller
          name="startedAt"
          control={control}
          defaultValue={new Date()}
          render={() => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                ampm={false}
                minutesStep={60}
                views={["day", "hours"]}
                label="Date Picker"
                value={getValues("startedAt")}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField sx={styles.input} {...params} />
                )}
              />
            </LocalizationProvider>
          )}
        />
      )}

      <TextField
        {...register("duration")}
        type="number"
        sx={styles.input}
        label="Duration (in hours)"
        variant="outlined"
        helperText={errors?.duration && errors?.duration?.message}
        error={!!errors?.duration}
      />

      <Controller
        name="developerId"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FormControl variant="standard">
            <InputLabel id="developer-id">Select Developer</InputLabel>
            <Select
              sx={styles.input}
              onChange={onChange}
              value={value}
              error={!!errors?.developerId}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 300 },
                  onScroll: loadMoreItems,
                },
              }}>
              <ListSubheader>In project</ListSubheader>
              {inDevelopers.map((developer) => (
                <MenuItem key={developer.id} value={developer.id}>
                  <Typography component="span">{developer.name}</Typography>{" "}
                  &nbsp; - &nbsp;
                  <Typography color={"primary"} component="span">
                    {developer.defaultRank}
                  </Typography>
                </MenuItem>
              ))}
              {!isEstimator && (
                <ListSubheader>Outside of the project</ListSubheader>
              )}
              {!isEstimator &&
                outDevelopers.map((developer) => {
                  return (
                    <MenuItem key={developer.id} value={developer.id}>
                      <Typography component="span">{developer.name}</Typography>{" "}
                      &nbsp; - &nbsp;
                      <Typography color={"primary"} component="span">
                        {developer.defaultRank}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Select>
            {!!errors?.developerId && (
              <FormHelperText sx={{ color: "#F46E6E" }}>
                {errors?.developerId?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Box sx={styles.buttonBox}>
        <Button variant="contained" type="submit">
          {taskId ? "Update" : "Create"}
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default TaskModal;
