import React from "react";
import AlertMessage from "components/Alert/AlertMessage";
import Box from "@mui/material/Box";
import { styles } from "components/Alert/styles";

type AlertLayoutType = {
  children: React.ReactNode;
};

const BasicAlert = () => {
  return (
    <Box sx={styles.alertControllerBox}>
      <AlertMessage />
    </Box>
  );
};

const AlertLayout = ({ children }: AlertLayoutType) => {
  return (
    <Box>
      {children}
      <BasicAlert />
    </Box>
  );
};

export default AlertLayout;
