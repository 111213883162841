import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSigninLazyQuery } from "graphql/generated";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { styles } from "screens/AuthScreens/styles";
import { userSchemaSignIn } from "validations/AuthValidations";
import { useAlertContext } from "context/AlertContext";
import { alertTypes } from "../../../constants";
import IconButton from "@mui/material/IconButton";

interface FormData {
  email: string;
  password: string;
}

const LogInScreen: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { handleChangeAlertParameters } = useAlertContext();
  const navigate = useNavigate();

  const [signIn] = useSigninLazyQuery();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(userSchemaSignIn),
  });

  const onSubmit = async (data: FormData) => {
    const res = await signIn({
      variables: {
        signinInput: data,
      },
    });

    if (res.error) {
      const errors: any = res.error?.graphQLErrors[0];
      if (errors[0].status === 400 && errors[1].status === 400) {
        setError(errors[0].field, {
          type: "custom",
          message: errors[0].message,
        });
        setError(errors[1].field, {
          type: "custom",
          message: errors[1].message,
        });
      } else {
        handleChangeAlertParameters({
          isOpen: true,
          message: errors[0].message,
          type: alertTypes.error,
        });
      }
    }

    if (res.data) {
      localStorage.setItem("token", res.data.signin.accessToken);
      navigate(0);
    }
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Container sx={styles.container} maxWidth="xl">
      <Typography sx={styles.title} align="center" variant="h4">
        Sign in
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.inputContainer}>
          <TextField
            {...register("email")}
            sx={styles.input}
            label="Email Adress"
            variant="outlined"
            helperText={errors?.email && errors?.email?.message}
            error={!!errors?.email}
          />
          <TextField
            {...register("password")}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleShowPassword}>
                  {!showPassword ? (
                    <VisibilityIcon
                      color={errors?.password ? "error" : "inherit"}
                    />
                  ) : (
                    <VisibilityOffIcon
                      color={errors?.password ? "error" : "inherit"}
                    />
                  )}
                </IconButton>
              ),
            }}
            type={showPassword ? "text" : "password"}
            sx={styles.input}
            label="Password"
            variant="outlined"
            helperText={errors?.password && errors?.password?.message}
            error={!!errors?.password}
          />
        </Box>
        <Button sx={styles.button} variant="contained" type="submit">
          <Typography variant="button">sign in</Typography>
        </Button>
      </form>
      <Box sx={styles.linksContainer}>
        <Link to="/password-reset">
          <Typography variant="body2">Forgot password?</Typography>
        </Link>
      </Box>
    </Container>
  );
};

export default LogInScreen;
