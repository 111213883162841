import { onError } from "apollo-link-error";
import router from "router/AppRouter";

interface Error {
  status: number;
  message: string;
}

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    const error = graphQLErrors[0] as unknown as Error[];

    if (error[0]?.status === 401) {
      localStorage.setItem("token", "");
      window.location.reload();
    }

    if (error[0]?.status === 403) {
      router.navigate("/", { replace: true });
    }

    if (error[0]?.status === 404) {
      router.navigate("not-found");
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export default errorLink;
