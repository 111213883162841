import { Components } from "@mui/material";

export const components: Components = {
  MuiSwitch: {
    styleOverrides: {
      track: {
        backgroundColor: "#0f59ff",
      },
      thumb: {
        backgroundColor: "#0f59ff",
      },
    },
  },
  MuiChip: {
    variants: [
      {
        props: {
          variant: "active",
        },
        style: {
          backgroundColor: "rgba(99,178,68,0.5)",
        },
      },
      {
        props: {
          variant: "inactive",
        },
        style: {
          backgroundColor: "rgba(197,171,16,0.49)",
        },
      },
      {
        props: {
          variant: "banned",
        },
        style: {
          backgroundColor: "rgba(182,0,0,0.3)",
        },
      },
    ],
  },
};

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    active: true;
    inactive: true;
    banned: true;
  }
}
