export const getStyles = ({ isChart = false }) => ({
  ganttContainer: {
    width: "calc(100% - 10px)",
    "& .gantt_grid_head_name": {
      maxWidth: isChart ? "auto" : "unset",
    },
    '& .gantt_cell[data-column-name="name"]': {
      maxWidth: isChart ? "auto" : "unset",
    },
    '& .gantt_cell[data-column-name="priority"] .gantt_tree_content': {
      justifyContent: "center",
    },
  },
});
