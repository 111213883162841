import React, { useState, useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { limitItemsCount } from "constants/index";
import ActionsTable from "components/ProjectsTable/TableActions";
import { useGetProjectsLazyQuery } from "graphql/generated";
import { ProjectsRowEntity } from "screens/ProjectsScreen/types";
import styles from "./styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useUserAccess } from "../../hooks";

interface ProjectsTableProps {
  rows: Array<ProjectsRowEntity>;
  rowCount: number;
  removeProject: (id: number) => () => void;
  setPageNumberState: (page: number) => void;
  page: number;
}

const formatRows = (rows: Array<ProjectsRowEntity>) => {
  return rows.map((row: ProjectsRowEntity) => {
    const createdTime = new Date(Number(row.createdAt));
    const updatedTime = new Date(Number(row.updatedAt));
    const isTotalHoursExist = !!row.totalHours;
    return {
      ...row,
      createdAt: format(createdTime, "dd-MM-yyyy 'at' h:mm a "),
      updatedAt: format(updatedTime, "dd-MM-yyyy 'at' h:mm a "),
      totalHours: isTotalHoursExist ? row.totalHours : "-",
    };
  });
};

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  rows,
  rowCount,
  removeProject,
  setPageNumberState,
  page,
}) => {
  const navigate = useNavigate();
  const handleEdit = (id: string) => {
    navigate(`/project/${id}`);
  };
  const { isEstimator } = useUserAccess();

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 50,
        minWidth: 20,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              fontSize="12px"
              color="#aaa">
              #{params.value}
            </Box>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        flex: 2,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              fontWeight="500"
              fontSize="15px"
              sx={{ cursor: "pointer", width: "100%" }}
              onClick={() => handleEdit(params.id)}>
              {params.value}
            </Box>
          );
        },
      },
      {
        field: "totalHours",
        flex: 0.5,
        headerName: "Total Hours",
        sortable: false,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: 220,
        minWidth: 100,
        sortable: false,
      },
      {
        field: "updatedAt",
        headerName: "Update At",
        width: 220,
        minWidth: 100,
        sortable: false,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          const cellColor = params.value === "ACTIVE" ? "#63b244" : "#c5ab10";

          return (
            <Box display="flex" alignItems="center" color={cellColor}>
              {params.value}
            </Box>
          );
        },
      },
      ...(!isEstimator
        ? [
            {
              field: "actions",
              headerName: "Actions",
              renderCell: (params: any) => {
                return (
                  <ActionsTable {...{ params }} removeProject={removeProject} />
                );
              },
              width: 20,
              minWidth: 100,
              sortable: false,
            },
          ]
        : []),
    ],
    [removeProject]
  );

  const [rowState, setRowState] = useState<Array<ProjectsRowEntity>>(rows);
  const [rowCountState, setRowCountState] = useState<number>(rowCount);

  const [getProjects, { loading }] = useGetProjectsLazyQuery();

  useEffect(() => {
    setRowCountState((prevRowCountState) => rowCount ?? prevRowCountState);
  }, [rowCount]);

  useEffect(() => {
    setRowState(formatRows(rows));
  }, [rows]);

  const handlePageChange = async (pageNumber: number) => {
    setPageNumberState(pageNumber);

    const res = await getProjects({
      variables: {
        offset: pageNumber * limitItemsCount,
        limit: limitItemsCount,
      },
    });

    const newRows = res.data && res.data.getProjects.projects;

    if (newRows) setRowState(formatRows(newRows));
  };

  return (
    <DataGrid
      page={page}
      rows={rowState}
      columns={columns}
      pageSize={limitItemsCount}
      rowCount={rowCountState}
      paginationMode="server"
      onPageChange={handlePageChange}
      loading={loading}
      checkboxSelection={false}
      autoHeight
      autoPageSize
      pagination
      disableSelectionOnClick
      disableColumnMenu
      sx={styles}
    />
  );
};

export default ProjectsTable;
