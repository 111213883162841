import React from "react";
import { ListItem } from "@mui/material";
import {
  ListItemButtonCustom,
  ListItemIconCustom,
  ListItemTextCustom,
} from "components/ListItemMenu/styles";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

interface ListItemMenuProps {
  open?: boolean;
  title?: string;
}

const ListItemMenu: React.FC<ListItemMenuProps> = ({ open, title }) => {
  const getItemIcon = () => {
    if (title === "Проекты") {
      return <HomeIcon />;
    }
    if (title === "Пользователи") {
      return <PersonOutlineIcon />;
    }
    if (title === "Разработчики") {
      return <PersonIcon />;
    }
    if (title === "Настройки") {
      return <SettingsIcon />;
    }
  };

  return (
    <ListItem key={title} disablePadding sx={{ display: "block" }}>
      <ListItemButtonCustom open={open}>
        <ListItemIconCustom open={open}>{getItemIcon()}</ListItemIconCustom>
        <ListItemTextCustom primary={title} open={open} />
      </ListItemButtonCustom>
    </ListItem>
  );
};

export default ListItemMenu;
