export const styles = {
  buttonCreate: {
    marginBottom: "22px",
  },
  buttonIcon: {
    marginRight: "4px",
    position: "relative",
    top: "-1px",
  },
};
