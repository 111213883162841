import { alertTypes } from "../../constants";
import * as GENERATED from "../generated/index";
import { GET_PROJECT } from "../queries/getProject.gql";
import { useAlertContext } from "../../context/AlertContext";

export const useSortSprint = (
  projectId: string | number,
  withNotification = false
) => {
  const { handleChangeAlertParameters } = useAlertContext();

  return GENERATED.useSprintSortingMutation({
    refetchQueries: () => [
      {
        query: GET_PROJECT,
        variables: {
          projectId: +projectId,
        },
      },
    ],
    fetchPolicy: "network-only",
    onCompleted: () => {
      handleChangeAlertParameters({
        isOpen: withNotification,
        type: alertTypes.success,
        message: "Sprints успешно відсортовано",
      });
    },
    onError: () => {
      handleChangeAlertParameters({
        isOpen: true,
        type: alertTypes.error,
        message: "Произошла ошибка",
      });
    },
  });
};
